import React from 'react';

function Stream(props) {
    let items = props.items && props.items.map(item => {
        return <a href='#' className='slds-context-bar__label-action' style={{display: "inline-block"}}>{item}</a>
    })
    return <div style={{padding: "8px", ...props.style}}>
        <marquee direction="right">{items}</marquee>
    </div>
}

export default Stream;