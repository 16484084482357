import { toDate } from '../config';
import auth from '../qrf/service/auth';
import QObject from './QObject';
import Scrip from './Scrip';

class Order extends QObject {

    constructor() {
        let user = auth.currentUser();
        let statusOptions = [{ label: "Draft", id: "draft" }, { label: "Publish", id: "publish" }, { label: "Pending", id: "pending" }, { label: "Executed", id: "executed" }, { label: "Canceled", id: "canceled" }];
        let properties = [
            { type: "hidden", label: "ID", name: "id" },
            { type: "hidden", label: "Trade", name: "trade", Cell: row => row.symbol },
            { type: "hidden", label: "User", name: "user", value: user._id },
            { type: "hidden", label: "Breakup", name: "breakup" },
            { type: "text", readonly: true, label: "Symbol", name: "symbol", scope: ["listview"] },
            { type: "select", id: "action", label: "Action", name: "action", options: [{ label: "Buy", id: "buy" }, { label: "Sell", id: "sell" }], scope: ["listview"] },
            { type: "number", label: "Quantity", name: "qty", scope: ["listview"] },
            { type: "text", label: "Price", name: "price", scope: ["listview"] },
            { type: "calendar", label: "Execution Date", name: "executedAt", scope: ["listview"], Cell: row => toDate(row.executedAt) },
            { type: "select", id: "status", label: "Status", name: "status", options: statusOptions, scope: ["listview"], selection: [{ label: "Draft", id: "draft" }], Cell: row => statusOptions.filter(opt => opt.id == row.status)[0].label },
            { type: "text", readonly: true, label: "Amount", name: "amount", scope: ["listview"], Cell: row => (row.qty * row.price).toFixed(2) },
            { type: "hidden", name: "parent", default: 0},
            {
                name: "actions", type: "icons", label: "Actions",
                values: [
                    {
                        label: "Edit",
                        value: "edit",
                        title: "Edit"
                    },
                    {
                        label: "Delete",
                        value: "delete",
                        title: "Delete"
                    }
                ], scope: ["listview"]
            }
        ];
        super(properties, "Order");
    }
}

export default Order;