import axios from "axios";
import storage from "./storage";
import { getHost } from "../../config";

const http = {
    getUrl: function (url = '', headers = {}) {
        return new Promise((resolve, reject) => {
            axios.get(url, headers).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    get: function (url = '') {
        const token = storage.fromSession("token");
        if (!url.includes("http")) {
            url = getHost() + "/" + url;
        }
        return new Promise((resolve, reject) => {
            axios.post(url, { token }).then((result) => {
                resolve(result.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    post: function (url = '', data = {}) {
        if (!data.token) {
            const token = storage.fromSession("token");
            data.token = token;
        }
        return new Promise((resolve, reject) => {
            if (!url.includes("http")) {
                url = getHost() + "/" + url;
            }
            axios.post(url, data).then((result) => {
                resolve(result.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    submit: function (url = '', data = {}) {
        const token = storage.fromSession("token");
        data.token = token;
        return new Promise((resolve, reject) => {
            axios.post(url, data).then((result) => {
                resolve(result.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    getObject: function (obj, id) {
        return this.get(obj.getApi + "/" + id);
    }
}

export default http;