import http from "../qrf/service/http";
import storage from "../qrf/service/storage";

class QObject extends Object {

    constructor(properties = [], objectName = "", component = null) {
        super(properties);
        this.name = objectName;
        this.properties = properties;
        this.getApi = objectName.toLocaleLowerCase() + "/get";
        this.updateApi = objectName.toLocaleLowerCase() + "/update";
        this.deleteApi = objectName.toLocaleLowerCase() + "/delete";
        this.saveApi = objectName.toLocaleLowerCase() + "/save";
        this.component = component;
        http.component = component;
        this.http = http;
    }

    set(key, value) {
        this.properties.map(prop => prop.name === key? prop.value = value : "");
        return this;
    }

    get(key, data = [], scope="") {
        let elems = this.properties.filter(prop => prop.name === key);
        let method = "get" + key.charAt(0).toUpperCase() + key.slice(1);
        if (typeof this[method] === "function") {
            return this[method](elems[0], data);
        }        
        let elem = elems && elems[0];
        let user = storage.getUser();
        let group = user.properties.filter(prop => prop.name === "group")[0];
        if (elem && elem.scope && elem.scope.length > 0) {  
            if (!elem.scope.includes(group.value)) {
                //return {};
            }
        }
        return elem;
    }

    getProperties(match = "") {
        let props = this.properties;
        if (match) {
            props = props.filter(prop => (prop.scope && prop.scope.includes(match)) || prop.name === match);
        }
        if (this.filterElements) {
            this.filterElements(match);
        }
        return props;
    }

    update(id, key, value, callback) {
        http.post(this.updateApi, {id, set: {[key]: value}}).then(res => {
            callback(res)
        })
    }

    setData(data) {
        this.getProperties().map(prop => {
            if (data[prop.name]) {
                prop.value = data[prop.name];
            }
        })
    }

    // searchAll(options = []) {
    //     return options.map(option => {
    //         this.toSearch(option)
    //     })
    // }

    toSearch(value) {        
        let $or = [];
        this.getProperties("searchable").map(prop => {
            if (prop.type === "text") {
                let cond = {};
                cond[prop.name] = {$regex: value, $options: "i"};
                $or.push(cond);
            }
        })
        return {$or};
    }

    removeItem(id, callback) {
        let user = storage.getUser().get("_id").value;
        http.post(this.deleteApi, {id, user}).then(res => {
            if (res.status) {
                callback(res);
            }
        })
    }

    save(item) {
        return http.post(this.saveApi, {item});
    }

    mapStateToItem() {
        let item = {};
        if (this.component) {
            let state = this.component.state;
            this.properties.map(prop => item[prop.name] = state[prop.name]);
        }
        return item;
    }

    store(callback = function() {}) {
        let component = this.component;
        if (component) {
            component.setState({loading: true});
        }
        let item = this.mapStateToItem();
        this.http.post(this.saveApi, {item}).then(data => {
            if (component) {
                component.setState({loading: false});
            }
            callback(data);
        });
        return item;
    }

    has(key) {
        return this.properties.filter(prop => prop.name === key).length > 0;
    }

    getFilters() {
        let filters = [];
        this.properties.map(prop => {
            if (prop.filter === true) {
                filters.push(prop);
            }
        });
    }

    fetch(id, comp) {
        http.get(this.getApi + "/" + id).then(res => {
            comp.setState({[this.name]: res.data});
        })
    }

    fetchItems(comp) {
        http.get(this.getApi).then(res => {
            comp.setState({items: res.data.items});
        })
    }
}

export default QObject;