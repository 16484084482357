import React from 'react';
import Icon from '@salesforce/design-system-react/components/icon';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import DropdownTrigger from '@salesforce/design-system-react/components/menu-dropdown/button-trigger';
import Button from '@salesforce/design-system-react/components/button';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ExpressionBuilder from '../ExpressionBuilder';
import Modal from '@salesforce/design-system-react/components/modal';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import MultiSelectCombo from '../../element/MultiSelectCombo';
import storage from '../../service/storage';

class DataTableHeader extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

		}
	}

	controls() {
		let user = storage.getUser();
		return (
			<React.Fragment>
				{
					this.props.pageControls && this.props.pageControls.map(pc => {
						if (pc && pc.type === "list") {
							return (
								<PageHeaderControl>
									<Dropdown
										align="right"
										assistiveText={{ icon: pc.label || 'Change view' }}
										iconCategory="utility"
										iconName="settings"
										iconVariant="more"
										id="page-header-dropdown-object-home-content-right-2"
										options={pc.options || []}
									>
										<DropdownTrigger>
											<Button
												assistiveText={{ icon: pc.label || 'Change view' }}
												iconCategory="utility"
												iconName="table"
												iconVariant="more"
												variant="icon"
											/>
										</DropdownTrigger>
									</Dropdown>
								</PageHeaderControl>
							)
						} else {
							return <PageHeaderControl style={pc && pc.style}>{pc}</PageHeaderControl>;
						}
					})
				}

				{/* <PageHeaderControl>
					<Button
						assistiveText={{ icon: 'Edit List' }}
						iconCategory="utility"
						iconName="edit"
						iconVariant="border-filled"
						variant="icon"
					/>
				</PageHeaderControl>
				<PageHeaderControl>
					<Button
						assistiveText={{ icon: 'Refresh' }}
						iconCategory="utility"
						iconName="refresh"
						iconVariant="border-filled"
						variant="icon"
					/>
				</PageHeaderControl>
				 */}
				{!this.props.noFilter && <PageHeaderControl>
					<ButtonGroup id="button-group-page-header-controls">
						{/* <Button
							assistiveText={{ icon: 'Charts' }}
							iconCategory="utility"
							iconName="chart"
							iconVariant="border-filled"
							variant="icon"
						/> */}
						{storage.getUser().is("admin") && <Button
							assistiveText={{ icon: 'Filters' }}
							iconCategory="utility"
							iconName="filterList"
							iconVariant="border-filled"
							variant="icon"
							onClick={e => this.setState({ filterModal: true })}
						/>}
					</ButtonGroup>
				</PageHeaderControl>}
			</React.Fragment>
		);
	}
	actions() {
		return (
			<PageHeaderControl>
				<ButtonGroup id="button-group-page-header-actions">
					{this.props.pageActions()}
				</ButtonGroup>
			</PageHeaderControl>
		);
	}
	render() {
		let title = this.props.list && this.props.list.label || "";
        let total = this.props.totalCount || this.props.totalItems;
        let displayOptions = [];
        for (let index = 1; index <= Math.ceil(total / 100); index += 1) {
            let nn = index * 100;
            if (nn > total) {
                nn = total;
            }
            let nextOption = {id: nn, label: nn};
            displayOptions.push(nextOption);
        }
		return (
			<>
				<PageHeader
					className="align-div"
					icon={
						<Icon
							assistiveText={{ label: this.props.heading || 'Scrips' }}
							category="standard"
							name="asset_object"
						/>
					}
					info={<div className='last-updated'><Dropdown
                        assistiveText={{ icon: 'Display Results' }}
                        buttonClassName="slds-button_icon-small"
                        id="page-header-display-results-dropdown"
                        options={displayOptions || []}
                        disabled={this.props.loading}
                        onSelect={option => {this.props.displayResults(option); this.setState({displayResults: option.id})}}
                        openOn="hover"
                        value={this.state.displayResults}
                        label={this.state.displayResults || this.props.totalItems}
                    /> items out of {total} {this.props.headerInfo || ""}
                        
                    </div>}
					label={this.props.heading || 'Scrips' }
					nameSwitcherDropdown={
						<Dropdown
							assistiveText={{ icon: 'Name Switcher' }}
							buttonClassName="slds-button_icon-small"
							buttonVariant="icon"
							iconCategory="utility"
							iconName="down"
							id="page-header-name-switcher-dropdown"
							options={this.props.lists || []}
							disabled={this.props.loading}
							onSelect={option => this.props.onListChange(option)}
							openOn="hover"
						/>
					}
					onRenderActions={() => this.actions()}
					onRenderControls={() => this.controls()}
					title={title || "All"}
					truncate
					variant="object-home"
				/>

				{this.state.filterModal && <Modal
					dismissOnClickOutside={false}
					isOpen={this.state.filterModal}
					onRequestClose={() => this.setState({ filterModal: false })}
					size="medium"
					heading="Create Filter"
				>
					<div style={{ padding: "20px" }}>
						<ExpressionBuilder lists={this.props.lists} events={{afterSave: () => {
                            this.setState({ filterModal: false });
                            this.props.refresh("list");
                        }}} object={this.props.object} />
					</div>
				</Modal>}
			</>
		)
	}
}

export default DataTableHeader;