import React from 'react';
import Card from '@salesforce/design-system-react/components/card';
import RadioButtonGroup from '@salesforce/design-system-react/components/radio-button-group';
import Radio from '@salesforce/design-system-react/components/radio-button-group/radio';
import Button from '../element/Button';
import Text from '../element/Text';
import Checkbox from '../element/Checkbox';
import Pills from './Pills';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Search from '../../components/scrip/Search';
import Calendar from './Calendar';

class Form extends React.Component {

    constructor(props) {
        super(props);
        let selection = {};
        this.state = {
            item: props && props.item || {},
            errors: {},
            num1: Math.round(Math.random() * 10),
            num2: Math.round(Math.random() * 10),
            captchaError: "",
            selection
        }
        this.hasItem = JSON.stringify(props.item) === "{}"? false : true;
        this.state.elements = this.props.object.getProperties(this.props.scope || props.object.scope).filter(ele => ele.editable !== false);
        if (this.hasItem) {
            this.state.elements.map(ele => {
                if (ele.type === "select") {
                    selection[ele.name] = ele.options.filter(opt => opt.id === props.item[ele.name]);
                }
            });
            this.state.selection = selection;
        }
    }

    componentDidUpdate(p, s) {
        let elements = this.props.object.getProperties(this.props.scope);
        if (elements.length !== this.state.elements.length) {
            this.setState({elements});
        }
    }

    required(value) {
        return !!value;
    }

    alphabet(value) {
        return value.match(/^[A-Za-z ]+$/);
    }

    applyValidations(validations, value) {
        let valid = true;
        validations && validations.map(validation => {
            if (valid && typeof this[validation] === "function") {
                valid = this[validation](value);
            }
        });
        return valid;
    }

    onChange(ele, value) {
        this.applyValidations(ele.validations, value);
        let item = { ...this.state.item }
        item[ele.name] = value;
        if (this.props.onChange) {
            item = this.props.onChange(ele, item);
        }
        this.setState({ item });
    }

    onFocusChange(ele) {
        this.validate(ele)
        if (typeof this.props.onFocusChange === "function") {
            this.props.onFocusChange(ele, { ...this.state.item }, (ele, item) => {
                if (ele.error) {
                    let errors = { ...this.state.errors };
                    errors[ele.name].push(ele.error);
                    this.setState({ errors });
                }
                this.setState({item});
            });
        }
    }

    getValue(ele) {
        let eleObject = this.props.object.get(ele.name);
        let value = typeof ele.Cell === "function"? ele.Cell(this.state.item): (eleObject && eleObject.value);
        if (this.state.item[ele.name]) {
            value = this.state.item[ele.name];
        }
        return value;
    }

    getInput(ele) {
        let required = ele.validations && ele.validations.includes("required");
        switch (ele.type) {
            case "text":
            case "password":
            case "email":
            case "tel":
            case "number":
                return <Text form readOnly={ele.readOnly || ele.readonly} type={ele.type} onChange={value => this.onChange(ele, value)} name={ele.name} value={this.getValue(ele)} label={ele.label} required={required} error={this.state.errors[ele.name]} onBlur={value => this.onFocusChange(ele)} prefix={ele.prefix} suffix={ele.suffix} maxLength={ele.maxLength} style={ele.style} placeholder={ele.placeholder} fieldgroup={ele.fieldgroup} />
            case "checkbox":
                return <Checkbox onChange={value => this.onChange(ele, value)} name={ele.name} label={ele.label} fieldgroup={ele.fieldgroup} variant="toggle" value={this.getValue(ele)} />
            case "radiogroup":
                return <div>
                    <RadioButtonGroup
                        labels={{ label: ele.label || "", error: this.state.errors[ele.name] && this.state.errors[ele.name].length > 0 && ele.label + " " + this.state.errors[ele.name].join(" and ") }}
                        onChange={(event) => this.onChange(ele, event.target.value)}
                        disabled={ele.disabled}
                        required={required}
                        name={ele.name}
                        errorId={ele.errorId}
                        fieldgroup={ele.fieldgroup}
                    >
                        {ele.options.map((opt) => (
                            <Radio
                                key={opt.namme}
                                id={opt.name}
                                labels={{ label: opt.label }}
                                value={opt.value}
                                checked={this.state.item[ele.name] === opt.value}
                                variant="button-group"
                                fieldgroup={ele.fieldgroup}
                            />
                        ))
                        }
                    </RadioButtonGroup>
                </div>
            case "multiselect":
                return ele.subtype === "pills" && <Pills label=
                    {ele.label} pills={ele.pills} fieldgroup={ele.fieldgroup} />
            case "select":
                return <Combobox
                    id={ele.id || "select-list-" + Math.random().toFixed(10)}
                    events={{
                        onSelect: (event, data) => {
                            if (this.props.action) {
                                // this.props.action('onSelect')(
                                //     event,
                                //     ...Object.keys(data).map((key) => data[key])
                                // );
                            } else if (console) {
                                console.log('onSelect', event, data);
                            }
                            let item = { ...this.state.item }
                            item[ele.name] = data.selection.length && data.selection[0].id || "";
                            this.setState({
                                item,
                                selection: {...this.state.selection, [ele.name]: data.selection},
                            });
                        },
                    }}
                    labels={{
                        label: ele.label,
                        placeholder: ele.label,
                    }}
                    options={ele.options}
                    selection={this.state.selection[ele.name] || ele.selection}
                    value={this.state.item[ele.name] || (ele.selection && ele.selection[0].id)}
                    variant="readonly"
                    required={ele.required}
                    errorText={this.state.errors[ele.name] && this.state.errors[ele.name].length ? this.state.errors[ele.name] : ""}
                    fieldgroup={ele.fieldgroup}
                />
            case "button":
                return <Button style={ele.style} fieldgroup={ele.fieldgroup} />
            case "search":
                if (this.props.action === "edit" && ele.editable === false) {
                    return undefined;
                }
                return <Search value={this.state.item[ele.name]} label={ele.label} object={ele.object} onSelect={selection => this.onChange(ele, selection.length && selection[0].id || "")} />
            case "calendar":
                return <Calendar value={this.state.item[ele.name]} label={ele.label} onChange={date => this.onChange(ele, date)} />
            default:
                if (typeof ele.type === "object") {
                    return ele.type;
                }
                return undefined
        }
    }

    submit(e) {
        e.preventDefault();
        if (this.validate()) {
            this.props.onSubmit(this.state.item);
        }
    }

    validate(field = null) {
        let captchaError = "";
        if (!this.props.noCaptcha && field === null && this.state.num1 + this.state.num2 != this.state.captcha) {
            captchaError = "Invalid captcha";
        }
        let data = { ...this.state.item };
        let errors = { ...this.state.errors }
        let elements = field === null ? this.state.elements : [field];
        elements.map(ele => {
            let value = data[ele.name];
            errors[ele.name] = [];
            if (ele.validations) {
                ele.validations.map(validation => {
                    if (validation === "required" && !value) {
                        errors[ele.name].push(ele.label + " is mandatory");
                    } else if (validation === "email") {
                        let re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                        if (!re.test(value)) {
                            errors[ele.name].push(ele.label + " is not a valid email");
                        }
                    }
                    else if (typeof validation === "object") {
                        if (!validation.validator(value)) {
                            errors[ele.name].push(validation.message);
                        }
                    }
                })
            }
        })
        this.setState({ errors, captchaError });
        let err = Object.keys(errors).filter(i => errors[i].length > 0)
        return !err.length && !captchaError;
    }

    render() {
        let fields = this.state.elements.map(ele => {
            return this.getInput(ele);
        });
        fields.map((fld, idx) => {
            if (fld) {
                let fg = null;
                if (fld.props.fieldgroup && fld.props.fieldgroup.label) {
                    fg = fld.props.fieldgroup.label;
                    let gf = [];
                    fields.map((fld2, idx2) => {
                        if ( fld2 && fld2.props && fld2.props.fieldgroup && fld2.props.fieldgroup.label === fg) {
                            fields[idx2] = undefined;
                            gf.push(fld2);
                        }
                    })
                    fields[idx] = gf;                    
                }
            }
        })
        fields.map((grpfld, idx) => {
            if (grpfld && typeof grpfld.push === "function") {
                fields[idx] = <div className='grouped'>{grpfld}</div>;
            }
        })
        return (
            <Card
                heading={this.props.heading || ""}
            >
            <form autoComplete='off'>
                {this.props.header && <h2>{this.props.header}</h2>}
                {fields}
                {!this.props.noCaptcha && <div>
                    <Text name="captcha" onChange={val => this.setState({ captcha: val })} value={this.state.captcha} label={`Enter Value of ${this.state.num1} + ${this.state.num2}`} />
                    {this.state.captchaError && <label style={{ color: "red" }}>{this.state.captchaError}</label>}
                </div>}
                <div style={{ textAlign: "center" }}><Button label={this.props.submit || "Submit"} row disabled={this.props.loading} onClick={(e) => this.submit(e)} afterButton={this.props.afterButton} /></div>
                </form>
            </Card>
        )
    }
}

export default Form;