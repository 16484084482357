import React from 'react';
import Button from '@salesforce/design-system-react/components/button';
import Layout from '../../qrf/grid/Layout';
import Header from '../common/Header';
import DataTable from '../../qrf/view/DataTable/DataTableView';
import TopNav from '../../qrf/view/TopNav';
import Modal from '../../qrf/view/Modal';
import Form from '../../qrf/view/Form';
import Trade from '../../objects/Trade';
import Order from '../../objects/Order';
import withRouter from '../../qrf/withRouter';
import Crumb from '../../qrf/element/Crumb';

class Orders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: {},
            lists: [],
            item: {trade: props.params.id, portfolio: props.params.portfolio, symbol: props.params.symbol}
        }
        this.state.object = new Order().set("symbol", props.params.symbol).set("trade", props.params.id);
        this.trade = new Trade();
    }

    componentDidMount() {
        this.trade.fetch(this.props.params.id, this);
    }

    onAction(action, item) {
        if (action.value === "edit") {
            this.setState({ renderModal: !this.state.renderModal, item });
        }
    }

    newItem(modal = false) {
        if (modal) {
            this.setState({ renderModal: !this.state.renderModal });
            return;
        }
    }

    pageActions() {
        let actions = [];
        actions.push(<Button onClick={e => this.newItem(true)} label="New" />);
        return actions;
    }

    save(item) {
        this.state.object.save(item).then(res => {
            this.setState({ renderModal: false, refresh: !this.state.refresh, item: {trade: this.props.params.id, symbol: this.props.params.symbol} });
        })
    }

    handleChange(ele, item) {
        if (ele.name === "price" || ele.name === "qty") {
            let price = item.price || 0;
            let qty = item.qty || 1;
            item.amount = (price * qty).toFixed(2);
        }
        return item;
    }

    validate(ele, item, cb) {
        if (ele.name === "buyPrice" && item.buyPrice > 0) {
            let si = item.startingInvestment;
            let qty = Math.round(si / item.buyPrice);
            item.qty = qty;
            item.startingInvestment = (item.buyPrice * qty).toFixed(2);
            let aqty = Math.round((item.maxInvestment - item.startingInvestment) / item.buyPrice);
            item.maxInvestment = ((qty + aqty) * item.buyPrice).toFixed(2);
            item.aqty = aqty;
            cb(ele, item, cb);
        }
    }

    render() {
        return <><Layout protected elements={[
            <Header />,
            <TopNav />,
            <DataTable
                heading="Orders"
                pageActions={() => this.pageActions()}
                object={this.state.object}
                onAction={(action, item) => this.onAction(action, item)}
                scope="listview"
                pagination={false}
                noFilter
                refresh={this.state.refresh}
                noList
                trail={<Crumb links={["My Portfolios:/my/portfolios", "Trade:/my/portfolio/"+this.props.params.portfolio]} />}
                id={this.props.params.id}
            />
        ]}
        />
            {this.state.renderModal && <Modal object={this.state.object} footer={[]} open={this.state.renderModal} onClose={e => this.setState({renderModal: false, item: {}})} >
                {this.state.scrip && "LTP: " + this.state.scrip.ltp}
                <Form noCaptcha item={this.state.item} onFocusChange={(ele, item, cb) => this.validate(ele, item, cb)} onSubmit={item => this.save(item)} object={this.state.object} onChange={(ele, form) => this.handleChange(ele, form)} />
            </Modal>}
        </>
    }
}

export default withRouter(Orders);