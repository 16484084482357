import React from 'react';
import withRouter from '../withRouter';
import { Link } from 'react-router-dom';

class NavLink extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let style = this.props.style || {};
        if (this.props.bold) {
            style.fontWeight = "bold";
        }
        let isActive = this.props.to === "/dashboard" && !window.location.href.includes("my")? "slds-is-active" : "";
        if (this.props.to && this.props.params && this.props.params.alias) {
            isActive = this.props.to.includes(this.props.params.alias)? "slds-is-active" : "";
        }
        let link = <Link target={this.props.target} rel={this.props.rel} className="slds-context-bar__label-action" to={this.props.to} style={style}>{this.props.label}</Link>;
        if (this.props.href) {
            link = <a style={style} target={this.props.target} rel={this.props.rel} className="slds-context-bar__label-action" href={this.props.href}>{this.props.label}</a>;
        }
        return (
            <>
                <li className={`slds-context-bar__item ${isActive}`}>
                    {link}
                </li>
            </>
        )
    }
}

export default withRouter(NavLink);