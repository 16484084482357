import React from 'react';
import Row from '../grid/Row'; 
import SButton from '@salesforce/design-system-react/components/button';
/** 
 * @example <Button onClick label style />
 */
class Button extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: props.disabled
        }
    }

    onClick(e) {
        this.setState({disabled: true});
        if (typeof this.props.onClick === "function") {
            this.props.onClick(e);
        }
    }

    render() {
        let variant = this.props.icon? "icon" : "brand";
        let button = <SButton disabled={this.props.disabled} onClick={e => this.onClick(e)} variant={variant} label={this.props.label || ""} style={{...this.props.style}} iconCategory='utility' iconName={this.props.icon} />
        if (this.props.row) {
            return (
                <Row button>
                    {button}
                    {this.props.afterButton}
                </Row>
            )
        } else {
            return button;
        }        
    }
}

export default Button;