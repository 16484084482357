import React from 'react';
import { Link } from 'react-router-dom';
import QObject from './QObject';
import Stock from './Stock';

class Portfolio extends QObject {

    constructor() {
        let obj = new Stock("portfolio");
        let properties = [
            { name: "user", type: "hidden", label: "User" },
            {
                name: "title", type: "text", label: "Title", scope: ["listview"],
                Cell: row => {
                    return (
                        <Link to={`/my/portfolio/${row._id}`}>{row.title}</Link>
                    )
                },
                validations:["required"]
            },
            { name: "description", type: "text", label: "Description", scope: ["listview"] },
            { name: "broker", type: "select", label: "Broker", scope: ["listview"], options: [{ label: "Angel One", id: "angelone" }], validations: ["required"] },
            {
                name: "actions", type: "icons", label: "Actions",
                values: [
                    {
                        label: "Edit",
                        value: "edit",
                        title: "Edit"
                    },
                    {
                        label: "Delete",
                        value: "delete",
                        title: "Delete"
                    }
                ],
                scope: ["listview"]
            }
        ];
        super(properties, "Portfolio", false);
        this.getApi = "portfolio/get"
        this.itemObject = obj;
    }

    getItems(prop, rows) {
        let lists = [];
        let items = [];
        let cols = prop.type.getProperties("portfolio");
        cols.push(prop.type.getProperties("actions")[0]);
        rows.length && rows.map(row => {
            lists.push({label: row.title, value: row._id});
            // let stocks = [];
            // row.stocks.map(stock => {
            //     let obj = {};
            //     obj.company = stock.scrip[0].company;
            //     obj.quantity = stock.quantity;
            //     obj.price = stock.price;
            //     stocks.push(obj)
            // })
            items = items.concat(row.items);
        })
        return {lists, items, cols}
    }
}

export default Portfolio;