import React from 'react';
import Spinner from '@salesforce/design-system-react/components/spinner';

class Loader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div style={{ position: 'relative', height: '5rem' }}>
				<Spinner
					size="small"
					variant="base"
					assistiveText={{ label: 'Loading...' }}
				/>
			</div>
        )
    }
}

export default Loader;