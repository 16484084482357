import React from 'react';

export const PAGE_LIMIT = process.env.REACT_APP_PAGE_LIMIT || 20;
export const APP_HOST = process.env.REACT_APP_HOST;

export const API_HOST = process.env.REACT_APP_API_HOST;

export const USER_API = API_HOST + '/user';
export const LOGIN_API = API_HOST + '/user/login/User';
export const ADMIN_API = API_HOST + '/admin';
export const ADMIN_ADD_API = ADMIN_API + '/add';
export const ADMIN_GET_API = ADMIN_API + '/get';
export const ADMIN_DELETE_API = ADMIN_API + '/delete';
export const ADMIN_UPLOAD_API = ADMIN_API + '/upload';
export const CONTENT_API = API_HOST + '/content';
export const GET_CONTENT_API = CONTENT_API + '/get';
export const SITE_API = API_HOST + '/site';
export const GET_SITE_API = SITE_API + '/get';
export const STOCK_API = API_HOST;
export const INDICES = {
    "all" : "All",
    "50": "Nifty 50",
    "100": "Nifty 100",
    "200": "Nifty 200",
    "500": "Nifty 500",
    "midcap100": "Midcap 100"
};
