import React from 'react';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';

class Text extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value || "",
            readOnly: props.readOnly || false
        }
    }

    componentDidUpdate(props) {
        if (this.props.value !== this.state.value) {
            this.setState({ value: this.props.value })
        }
    }

    handleChange(e) {
        this.setState({ value: e.target.value })
        if (typeof this.props.onChange === "function") {
            this.props.onChange(e.target.value);
        }
    }

    onClick(e) {
        if (this.props.form) {
            this.setState({ readOnly: !this.state.readOnly })
        }
    }

    onKeyDown(e) {
        if (e.keyCode === 13 || e.key === "Enter") {
            if (this.props.onEnter) {
                this.props.onEnter(e);
            }
        }
    }

    onBlur(e) {
        if (typeof this.props.onTabout === "function") {
            this.props.onTabout(e.target.value);
        }
    }

    render() {
        let step = 1;
        if (this.props.type === "decimal") {
            step = 0.1;
        }
        let error = this.props.error && this.props.error.length > 0 && this.props.error.join(" and ");
        let iconRight = false;
        if (this.props.iconRight) {
            let iconAttrs = this.props.iconRight.split(".");
            iconRight = <InputIcon
                assistiveText={{
                    icon: iconAttrs[2],
                }}
                name={iconAttrs[1]}
                category={iconAttrs[0]}
                onClick={() => {
                    if (typeof this.props.onRightIconClick === "function") {
                        this.props.onRightIconClick(this.props.id);
                    }
                }}
            />
        }
        return (
            <Input
                id={this.props.id || Math.random()}
                autoComplete="off"
                readOnly={this.state.readOnly}
                step={step}
                type={this.props.type}
                size={this.props.size || "small"}
                value={this.state.value}
                placeholder={this.props.placeholder}
                onChange={e => this.handleChange(e)}
                label={this.props.label}
                onKeyDown={e => this.onKeyDown(e)}
                required={this.props.required}
                errorText={error}
                onBlur={e => this.onBlur(e)}
                fixedTextLeft={this.props.prefix}
                maxLength={this.props.maxLength}
                fixedTextRight={this.props.suffix}
                styleInput={{ ...this.props.style }}
                iconRight={!this.props.loading && iconRight}
                hasSpinner={this.props.loading}
            />
        )
    }
}

export default Text;