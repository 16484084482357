import { config } from '../config';
import { isExpired, decodeToken } from "react-jwt";
import User from '../../objects/User';

class storage extends Object {
    constructor() {
        super();
        this.db = localStorage;
    }

    toSession(key, value) {
        if (typeof key === "object") {
            Object.keys(key).map(k => {
                this.db.setItem(config.STORAGE_PREFIX + k, key[k]);
            })
        } else {
            if (typeof value === "object") {
                value = JSON.stringify(value);
            }
            this.db.setItem(config.STORAGE_PREFIX + key, value);
        }
        return this;
    }

    fromSession(key) {
        let item = this.db.getItem(config.STORAGE_PREFIX + key);
        if (item && item.includes("{") || item && item.includes("[")) {
            item = JSON.parse(item);
        }
        return item;
    }

    isTokenExpired() {
        let token = this.db.getItem(config.STORAGE_PREFIX + "token");
        let tokenExpired = isExpired(token);
        return tokenExpired;
    }

    getUser(reload = true) {
        let token = this.db.getItem(config.STORAGE_PREFIX + "token");
        if (token && isExpired(token)) {
            this.clearSession();
            if (reload) {
                window.location.reload();
            }
        }
        if (token) {
            let decoded = decodeToken(token);
            return new User(decoded.user);
        } else {
            return new User();
        }
    }

    getUserId(reload = true) {
        return this.getUser(reload).get("_id").value;
    }

    clearSession() {
        this.db.setItem(config.STORAGE_PREFIX + "authenticated", "");
        this.db.setItem(config.STORAGE_PREFIX + "token", "");
    }

    remove(key) {
        this.db.removeItem(config.STORAGE_PREFIX + key);
    }
}

export default new storage();