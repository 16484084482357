import moment from 'moment';
import React from 'react';

class Charts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            duration: props.duration,
            candles: props.candles,
            rsi: props.rsi,
            moves: props.moves
        }
    }

    generateLineData(minValue, maxValue, maxDailyGainLoss = 1000) {
        var res = [];
        var time = new Date(Date.UTC(2018, 0, 1, 0, 0, 0, 0));
        for (var i = 0; i < 500; ++i) {
            var previous = res.length > 0 ? res[res.length - 1] : { value: 0 };
            var newValue = previous.value + ((Math.random() * maxDailyGainLoss * 2) - maxDailyGainLoss);

            res.push({
                time: time.getTime() / 1000,
                value: Math.max(minValue, Math.min(maxValue, newValue))
            });

            time.setUTCDate(time.getUTCDate() + 1);
        }

        return res;
    }

    generateHistogramData(minValue, maxValue) {
        var res = [];
        var time = new Date(Date.UTC(2018, 0, 1, 0, 0, 0, 0));
        for (var i = 0; i < 500; ++i) {
            res.push({
                time: time.getTime() / 1000,
                value: minValue + Math.random() * (maxValue - minValue)
            });

            time.setUTCDate(time.getUTCDate() + 1);
        }

        return res;
    }

    draw() {
        var container = document.getElementById('charts');
        container.innerHTML = "";
        var chart = window.LightweightCharts.createChart(container, {
            timeScale: {
                borderColor: "rgb(225, 226, 227)",
                rightOffset:9
            },
            overlayPriceScales: {
                scaleMargins: {
                    top: 0.6,
                    bottom: 0,
                }
            },
            rightPriceScale: {
                autoScale: true,
                scaleMargins: {
                    top: 0.1,
                    bottom: 0.08,
                }
            },
            autoSize: true,
            crosshair: {
                // Change mode from default 'magnet' to 'normal'.
                // Allows the crosshair to move freely without snapping to datapoints
                mode: window.LightweightCharts.CrosshairMode.Normal,

                // Vertical crosshair line (showing Date in Label)
                vertLine: {
                    color: '#9B7DFF',
                    labelBackgroundColor: '#9B7DFF',
                },

                // Horizontal crosshair line (showing Price in Label)
                horzLine: {
                    color: '#9B7DFF',
                    labelBackgroundColor: '#9B7DFF',
                }
            }
        });

        // var mainSeries = chart.addLineSeries({
        //     title: 'primary',
        //     priceFormat: {
        //         minMove: 1,
        //         precision: 0,
        //     },
        // });

        // mainSeries.setData(this.generateLineData(1000, 300000, 1500));

        var candleSeries = chart.addCandlestickSeries({

            priceFormat: {
                minMove: 1,
                precision: 0,
            },
            pane: 0
        });
        candleSeries.setData(this.state.candles);

        var secondSeries = chart.addLineSeries({
            title: 'RSI',
            priceFormat: {
                minMove: 1,
                precision: 0,
            },
            color: '#ff0000',
            pane: 1,
            lineWidth:1
        });
        secondSeries.setData(this.state.rsi);

        chart.timeScale().fitContent();
        // var thirdSeries = chart.addLineSeries({
        // 	title: 'third',
        // 	priceFormat: {
        // 		minMove: 1,
        // 		precision: 0,
        // 	},
        // 	color: '#00ff00',
        // 	pane: 1
        // });
        // thirdSeries.setData(this.generateLineData(0, 100, 20));

        // var fourthSeries = chart.addLineSeries({
        // 	title: 'fourth',
        // 	priceFormat: {
        // 		minMove: 1,
        // 		precision: 0,
        // 	},
        // 	color: '#ea6622',
        // 	pane: 2
        // });
        // fourthSeries.setData(this.generateLineData(0, 100, 20));

        // var volumeSeries = chart.addHistogramSeries({
        // 	secondary: 'volume',
        // 	priceScaleId: '',
        // 	pane: 0
        // });
        // volumeSeries.setData(this.generateHistogramData(100000, 3000000));

        // Create and style the tooltip html element
        const toolTip = document.createElement('div');
        toolTip.style = `height: 100px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 16px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 2px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
        toolTip.style.background = 'white';
        toolTip.style.color = 'black';
        toolTip.style.borderRadius = "5px";
        container.appendChild(toolTip);
        let moves = this.state.moves;
        // update tooltip
        chart.subscribeCrosshairMove(param => {
            if (
                param.point === undefined ||
                !param.time ||
                param.point.x < 0 ||
                param.point.x > container.clientWidth ||
                param.point.y < 0 ||
                param.point.y > container.clientHeight
            ) {
                toolTip.style.display = 'none';
            } else {
                // time will be in the same format that we supplied to setData.
                // thus it will be YYYY-MM-DD
                let format = "DD MMM, YYYY";
                if (typeof param.time === "number") {
                    param.time = (param.time + 19800 + 3600) * 1000;
                    format = "hh:mm";
                }
                let time = param.time;
                const dateStr = time.day + "-" + time.month + "-" + time.year;
                toolTip.style.display = 'block';
                //console.log([...param.seriesData.values()][0])
                const data = [...param.seriesPrices.values()][0];
                if (!data) {
                    toolTip.style.display = 'none';
                    return;
                }
                const price = data.close;
                let tkey = moment(param.time["year"] + "-" + param.time["month"] + "-" + param.time["day"], "YYYY-M-D").format("YYYY-MM-DD");
                let move = moves[tkey];
                let color = "red";
                if (move > 0) {
                    move = "+" + move;
                    color = "green";
                }
                toolTip.innerHTML = `<div id="tooltip">
                <span style="color: ${color}">${dateStr} (${move ? move + "%" : ""})</span>
            <ul>
                <li>
                    <ul>
                        <li>O:${data.open}</li>
                        <li>C:${data.close}</li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li>L:${data.low}</li>
                        <li>H:${data.high}</li>
                    </ul>
                </li>
            </ul>
</div>`;
                /**
                * <div style="color: ${'black'}">
                ${dateStr}
                </div>
                */
                // const coordinate = api.priceToCoordinate(price);
                // let shiftedCoordinate = param.point.x - 50;
                // if (coordinate === null) {
                // 	return;
                // }
                // shiftedCoordinate = Math.max(
                // 	0,
                // 	Math.min(container.clientWidth - toolTipWidth, shiftedCoordinate)
                // );
                // const coordinateY =
                // 	coordinate - toolTipHeight - toolTipMargin > 0
                // 		? coordinate - toolTipHeight - toolTipMargin
                // 		: Math.max(
                // 			0,
                // 			Math.min(
                // 				container.clientHeight - toolTipHeight - toolTipMargin,
                // 				coordinate + toolTipMargin
                // 			)
                // 		);
                toolTip.style.left = (param.point.x + 30) + 'px';
                toolTip.style.top = (param.point.y + 70) + 'px';
            }
        });
        function myClickHandler(param) {
            return;
            let exBox = document.getElementById("toolTip2");
            if (exBox) {
            container.removeChild(exBox);
            }
            if (!param.point) {
                return;
            }
            let curPrice = candleSeries.coordinateToPrice(param.point.y);
            console.log(`Click at ${param.point.x}, ${param.point.y}. The time is ${param.time}.`);

            const toolTip2 = document.createElement('div');
            const data = [...param.seriesPrices.values()][0];
                if (!data) {
                    toolTip2.style.display = 'none';
                    return;
                }
            toolTip2.id = "toolTip2";
        toolTip2.style = `height: 100px; position: absolute; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 2px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
        toolTip2.style.background = 'white';
        toolTip2.style.color = 'black';
        toolTip2.style.borderRadius = "5px";
        let html = `<ul><li>asasas</li></ul>`;
        toolTip2.innerHTML = html;
        toolTip2.style.left = (param.point.x + 5) + 'px';
                toolTip2.style.top = (param.point.y - 5) + 'px';
        container.appendChild(toolTip2);
        }
        
        //chart.subscribeClick(myClickHandler);
    }

    componentDidMount() {        
        this.draw();
    }

    componentDidUpdate(pp, ps) {
        if (this.state.duration !== this.props.duration) {
            this.setState({duration: this.props.duration, candles: this.props.candles, rsi: this.props.rsi, moves: this.props.moves}, () => {
                this.draw();
            });
        }
    }

    render() {
        return (
            <div id="charts" style={{ display: "block", width: "99%", height: "500px" }}></div>
        )
    }
}

export default Charts;