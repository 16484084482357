import Scrip from './Scrip';

class Stock extends Scrip {

    constructor() {
        let properties = [
            { name: "symbolname", type: "search", label: "Company", scope: ["portfolio"], Cell: (row) => {
                return row.symbolname || row.tradingsymbol;
            }, object: new Scrip()},
            { name: "ltp", type: "text", label: "LTP", scope: ["portfolio"] },
            { name: "quantity", type: "text", label: "Quantity", scope: ["portfolio"], Cell: row => row.quantity},
            { name: "averageprice", type: "text", label: "Price", scope: ["portfolio"], Cell: row => row.averageprice},
            { name: "investment", type: "text", label: "Investment", scope: ["portfolio"], Cell: row => {
                return Math.round(row.averageprice * row.quantity)}
            },
            { name: "pnl", type: "text", label: "PNL", scope: ["portfolio"], Cell: row => row.pnl || Math.round(row.ltp * row.quantity - row.averageprice * row.quantity)},
            { name: "producttype", type: "text", label: "Type", scope: ["portfolio"], Cell: row => row.producttype || row.product},
            { name: "stoploss", type: "text", label: "Stop Loss", scope: ["portfolio"] },
            { name: "targetprice", type: "text", label: "Target Price", scope: ["portfolio"] },
            {
                name: "actions", type: "icons", label: "Actions",
                values: [
                    {
                        label: "Edit",
                        value: "edit",
                        title: "Edit"
                    },
                    {
                        label: "Delete",
                        value: "delete",
                        icon: "anywhere_alert",
                        title: "Delete"
                    }
                ],
                width: "12rem",
                scope: ["portfolio"]
            }
        ];
        super(properties, "Stock");
        this.scope = "portfolio";
        
        this.getApi = "portfolio/get/items";
        this.updateApi = "portfolio/update";
        this.deleteApi = "portfolio/delete";
        this.saveApi = "portfolio/save";
    }    

    getActions(ele) {
        let actions = this.properties.filter(prop => prop.name === "actions" && prop.scope.includes("portfolio"));
        console.log(actions);
        return actions[0];
    }
}

export default Stock;