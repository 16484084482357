import React from 'react';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import Box from '../../qrf/grid/Box';
import Row from '../../qrf/grid/Row';
import Input from '@salesforce/design-system-react/components/input';
import Layout from '../../qrf/grid/Layout';
import Header from '../common/Header';
import TopNav from '../../qrf/view/TopNav';
import AppLauncherExpandableSection from '@salesforce/design-system-react/components/app-launcher/expandable-section';
import AppLauncherTile from '@salesforce/design-system-react/components/app-launcher/tile';
import Icon from '@salesforce/design-system-react/components/icon';
import storage from '../../qrf/service/storage';
import Form from '../../qrf/view/Form';
import EditForm from '../../qrf/view/EditForm';
import User from '../../objects/User';

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    getContent() {
        let user = storage.getUser();
        return <Form object={user} />
    }

    render() {
        return (
            <Layout elements={[<Header />, <TopNav />, <Box style={{padding: "15px"}}>{this.getContent()}</Box>]} />
        )
    }
}

export default Profile;