import React from 'react';

import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import GlobalNavigationBar from '@salesforce/design-system-react/components/global-navigation-bar';
import GlobalNavigationBarRegion from '@salesforce/design-system-react/components/global-navigation-bar/region';
import GlobalNavigationBarDropdown from '@salesforce/design-system-react/components/global-navigation-bar/dropdown';
import GlobalNavigationBarLink from '@salesforce/design-system-react/components/global-navigation-bar/link';

import Button from '@salesforce/design-system-react/components/button';
import Icon from '@salesforce/design-system-react/components/icon';

import AppLauncher from '@salesforce/design-system-react/components/app-launcher';
import AppLauncherExpandableSection from '@salesforce/design-system-react/components/app-launcher/expandable-section';
import AppLauncherTile from '@salesforce/design-system-react/components/app-launcher/tile';
import { Link, Navigate } from 'react-router-dom';
import withRouter from '../withRouter';
import NavLink from './NavLink';
import storage from '../service/storage';
import Stream from './Stream';
import http from '../service/http';
import { alias } from '../../components/scrip/ScripCommon';
import Indices from '../../components/common/Indices';

class TopNav extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sectors: []
        }
    }

    componentDidMount() {
        http.post("sector/getSectors").then(res => {
            if (res.status) {
                this.setState({sectors: res.data});
            }
        });
    }

    render() {
        const packs = [
            {
                label: 'All',
                value: '1',
                iconCategory: 'utility',
                iconName: 'table',
                href: '/my/packs/all'
            },
            {
                label: 'Metal',
                value: '2',
                iconCategory: 'utility',
                iconName: 'table',
                href: '/my/packs/metal',
            },
            {
                label: 'Cement',
                value: '3',
                iconCategory: 'utility',
                iconName: 'kanban',
                href: '/my/packs/cement',
            },
            {
                label: 'Energy',
                value: '4',
                iconCategory: 'utility',
                iconName: 'side_list',
                href: '/my/packs/energy',
            },
        ];

        if (this.state.navigate && !window.location.href.includes(this.state.navigate)) {
            return <Navigate to={this.state.navigate} />
        }
        let links = [
            {
                label: 'Dashboard',
                value: '1',
                iconCategory: 'utility',
                iconName: 'table',
                href: '/my/dashboard'
            },
            {
                label: 'Profile',
                value: '1',
                iconCategory: 'utility',
                iconName: 'table',
                href: '/my/profile'
            },
            {
                label: 'Radar',
                value: '2',
                iconCategory: 'utility',
                iconName: 'table',
                href: '/my/radar'
            }
        ];
        let indices = [
            {
                label: 'Nifty500',
                value: '1',
                iconCategory: 'utility',
                iconName: 'table',
                href: '/index/500'
            },
            {
                label: 'Midcap 100',
                value: '2',
                iconCategory: 'utility',
                iconName: 'table',
                href: '/index/midcap100'
            },
            {
                label: 'Smallcap 100',
                value: '2',
                iconCategory: 'utility',
                iconName: 'table',
                href: '/index/smallcap100'
            },
            {
                label: 'Microcap 250',
                value: '2',
                iconCategory: 'utility',
                iconName: 'table',
                href: '/index/microcap250'
            },
            {
                label: 'Nifty Bank',
                value: '2',
                iconCategory: 'utility',
                iconName: 'table',
                href: '/index/niftybank'
            }
        ];
        //if (storage.getUser().is("admin")) {
            links.push({
                label: 'Scan',
                value: '1',
                iconCategory: 'utility',
                iconName: 'table',
                href: '/my/scan'
            });
            links.push({
                    label: 'Portfolio',
                    value: '3',
                    iconCategory: 'utility',
                    iconName: 'table',
                    href: '/my/portfolios'
                });
        //}
        return (<div>
            <GlobalNavigationBar>
                <GlobalNavigationBarRegion region="primary">
                    {/* <AppLauncher
                        id="app-launcher-trigger"
                        triggerName="Lojifin"
                        onSearch={(e) => {
                            console.log('Search term:', e.target.value);
                        }}
                        modalHeaderButton={<Button label="App Exchange" />}
                    >
                        <AppLauncherExpandableSection title="Tile Section">
                            <AppLauncherTile
                                title="Marketing Cloud"
                                iconText="MC"
                                description="Send emails, track emails, read emails! Emails!"
                            />
                            <AppLauncherTile
                                title="Call Center"
                                description="The key to call center and contact center is not to use too many words!"
                                descriptionHeading="Call Center"
                                iconText="CC"
                            />
                        </AppLauncherExpandableSection>
                        <AppLauncherExpandableSection title="Small Tile Section">
                            <AppLauncherTile
                                title="Journey Builder"
                                iconText="JB"
                                size="small"
                            />
                            <AppLauncherTile
                                title="Sales Cloud"
                                iconNode={
                                    <Icon name="campaign" category="standard" size="large" />
                                }
                                size="small"
                            />
                        </AppLauncherExpandableSection>
                    </AppLauncher> */}
                </GlobalNavigationBarRegion>
                <GlobalNavigationBarRegion region="secondary" navigation>
                    {/* <NavLink {...this.props} to="/" label="Home" /> */}
                    <a className='slds-context-bar__label-action' href='/'>Home</a>
                    {!this.props.authenticated && <NavLink {...this.props} to="/page/about-us" label="About Us" />}
                    <GlobalNavigationBarDropdown
                        assistiveText={{ icon: 'Market' }}
                        id="primaryDropdown"
                        label="Market"
                        options={[{
                            label: 'Gainers',
                            value: '1',
                            iconCategory: 'utility',
                            iconName: 'table',
                            href: '/market/gainers'
                        },
                        {
                            label: 'Losers',
                            value: '1',
                            iconCategory: 'utility',
                            iconName: 'table',
                            href: '/market/losers'
                        }]}
                        onSelect={v => this.setState({ navigate: v.href })}
                        active={window.location.href.includes("market")}
                        align="left"
                    />
                    {/* <GlobalNavigationBarDropdown
                        assistiveText={{ icon: 'Packs' }}
                        id="primaryDropdown"
                        label="Packs"
                        options={packs}
                        onSelect={v => this.setState({navigate: v.href}) }
                    /> */}
                    <GlobalNavigationBarDropdown
                        assistiveText={{ icon: 'Indices' }}
                        id="primaryDropdown"
                        label="Indices"
                        options={indices}
                        onSelect={v => window.location.href = v.href}
                        active={window.location.href.includes("nifty")}
                        align="left"
                    />
                    {!this.props.authenticated && <NavLink {...this.props} to="/page/login" label="Login" />}
                    {this.props.authenticated && <GlobalNavigationBarDropdown
                        assistiveText={{ icon: 'My' }}
                        id="primaryDropdown"
                        label="My"
                        options={links}
                        onSelect={v => this.setState({ navigate: v.href })}
                        active={window.location.href.includes("my")}
                        align="left"
                        className='topnav-menu'
                    />}
                    {/* {this.props.authenticated && <GlobalNavigationBarDropdown
                        assistiveText={{ icon: 'Sectors' }}
                        id="primaryDropdown"
                        label="Sectors"
                        options={this.state.sectors}
                        onSelect={link => window.location.href = "/sector/" + alias(link.label)}
                        active={window.location.href.includes("sector")}
                        align="left"
                        className='topnav-menu'
                    />} */}
                    {/* <li className='slds-context-bar__item '><Stream items={["one", "two"]} /></li> */}
                </GlobalNavigationBarRegion>
            </GlobalNavigationBar>
            {/* <NavLink bold style={{color: "#1b96ff"}} target="_blank" href="https://upstox.com/open-account/?f=4WBWL4" label="Open Free DMAT Account" /> */}
            </div>
        )
    }
}

export default withRouter(TopNav);