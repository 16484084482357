import React from 'react';
import SLDSDataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import DataTableHeader from './DataTableHeader';
import '@salesforce/design-system-react/assets/styles/table.css';
import { loadLists, loadList, setItems } from '../../../components/scrip/ScripCommon';
import Buttons from '../../element/Buttons';
import Noitem from '../Noitem';
import storage from '../../service/storage';

const CustomDataTableCell = ({ children, ...props }) => {
	let cellprops = props.props;
	if (typeof cellprops.col.Cell === "function") {
		children = cellprops.col.Cell(props.item);
	}
	if (cellprops.col.name === "actions") {
		let actions = cellprops.object.get("actions", [], cellprops.object.scope || "listview");
		children = <Buttons item={props.item} onClick={(action) => onAction(props, cellprops, action, props.item)} actions={actions.values} />
	}

    function onAction(props, cellprops, action, item) {
        if (action.value === "delete") {
            cellprops.object.removeItem(item.id, () => {
            props.onAction(action, item);
            });
        } else {
            props.onAction(action, item);
        }
    }
	return (
		<DataTableCell title={cellprops.col.label}>
			{children}
		</DataTableCell>
	);
}
CustomDataTableCell.displayName = DataTableCell.displayName;

class DataTable extends React.Component {

	constructor(props) {
		super(props);
		let list = storage.fromSession("list");
		this.state = {
			sortColumn: 'company',
			sortColumnDirection: {
				opportunityName: 'asc',
			},
			items: [],
			selection: [],
			cols: [],
			object: props.object,
			filter: props.filter,
			hasMore: false,
			page: 1,
			search: props.search || {},
			curTab: props.curTab,
			list: list || props.list,
			id: props.id,
			limit: props.limit || 50,
			object: props.object,
			itemObject: props.object.has("items")? props.object.itemObject : null
		};
		//this.object = this.props.object;
		// this.itemObject = null;
		// if (this.object.has("items")) {
		// 	this.itemObject = this.object.itemObject;
		// }
		this.loadLists = loadLists.bind(this);
		this.loadList = loadList.bind(this);
	}

	componentDidMount() {       
		let object = this.state.itemObject || this.state.object;
		let cols =  object.getProperties(this.props.scope);
        let filter = { ...this.state.filter };
		if (this.props.object) {
            let alias = this.props.params && this.props.params.alias;
            if (alias === "gainers") {
                filter.move = "up"
            } else if (alias === "losers") {
                filter.move = "down"
            }
		}
		this.setState({ cols, filter }, () => {
            if (!this.props.noList) {
                this.loadLists(true);
            }
			this.loadList();
            this.setItems = setItems.bind(this);
        });
	}

	componentDidUpdate(prevProps, prevState) {
		let pfilter = this.state.filter || {};
		let nfilter = this.props.filter || {};
		if (JSON.stringify(nfilter) !== JSON.stringify(pfilter)) {
			this.setState({ filter: nfilter, items: [] }, () => {
				this.loadList();
			})
		} else if (JSON.stringify(this.props.search) !== JSON.stringify(this.state.search)) {
			this.setState({ search: this.props.search, items: [] }, () => {
				this.loadList();
			})
		} else if (this.props.object.name !== this.state.object.name) {
            this.setState({ object: this.props.object, items: [] }, () => {
				this.loadList();
			})
        } else if (this.props.refresh !== this.state.refresh) {
			this.setState({ refresh: this.props.refresh, object: this.props.object, items: [] }, () => {
				this.loadList();
                this.loadLists();
			})
		}
	}

	handleLoadMore() {
		if (!this.isLoading) {
			this.isLoading = true;
			let page = this.state.page + 1
			this.loadList(page);
		}
	}

	handleChanged = (event, data) => {
		this.setState({ selection: data.selection });
		//console.log(event, data);
		if (this.props.onSelectionChange) {
			this.props.onSelectionChange(data.selection);
		}
	};

	handleRowAction = (item, action) => {
		//console.log(item, action);
	};

	handleSort = (sortColumn, ...rest) => {
		if (this.props.log) {
			this.props.log('sort')(sortColumn, ...rest);
		}

		let sortProperty = sortColumn.property;
		const { sortDirection } = sortColumn;
		// if (sortProperty === "percentage") {
		// 	if (JSON.stringify(this.state.sort) !== JSON.stringify({ by: sortProperty, dir: sortDirection })) {
		// 		this.setState({ sortColumn: sortProperty, sortColumnDirection: { [sortProperty]: sortDirection }, items: [], sort: { sortBy: sortProperty, sortDir: sortDirection === "asc" ? 1 : -1 } }, () => {
		// 			this.loadList();
		// 		});
		// 	}
		// 	return;
		// }
		const newState = {
			sortColumn: sortProperty,
			sortColumnDirection: {
				[sortProperty]: sortDirection,
			},
			items: [...this.state.items],
		};

		// needs to work in both directions
		newState.items = newState.items.sort((a, b) => {
			let val = 0;
            if (sortProperty.includes(".")) {
                let parts = sortProperty.split(".");
                a = a[parts[0]];
                b = b[parts[0]];
                sortProperty = parts[1];
            }
            let val1 = a[sortProperty];
            let val2 = b[sortProperty];
			if (val1 > val2) {
				val = 1;
			}
			if (val1 < val2) {
				val = -1;
			}

			if (sortDirection === 'desc') {
				val *= -1;
			}

			return val;
		});

		this.setState(newState);
	};

	onListChange(list) {
		let id = list.id || list.value;
		if (id) {
			this.setState({ list, items: [] }, () => this.loadList(1, id));
			storage.toSession("list", list);
		} else {
			storage.remove("list");
			this.setState({ list: "", items: [] }, () => this.loadList());
		}
		if (typeof this.props.onListChange === "function") {
			this.props.onListChange(list);
		}
	}

    displayResults(option) {
		let limit = option.id;
        this.setState({limit, refresh: !this.state.refresh});
	}

    onAction(action, item) {
        if (action.value === "delete") {
            this.setState({refresh: !this.state.refresh});
        }
        return this.props.onAction && this.props.onAction(action, item)
    }

    refresh(section) {
        if (section === "list") {
            this.loadLists();
        }
    }

	render() {
		let object = this.state.object;		
		let rows = [];
		this.state.items.length > 0 && this.state.cols.map(col => {
			let label = col.label;
			let name = col.name;
			rows.push(<DataTableColumn
				isSorted={this.state.sortColumn === name}
				label={label}
				primaryColumn
				property={name}
				sortable
				sortDirection={this.state.sortColumnDirection[name]}
				width={col.width || "auto"}
			>
				<CustomDataTableCell props={{object, col}} onAction={(action, item) => this.onAction(action, item)} />
			</DataTableColumn>);
		})
		return (
			<div
				style={{
					height: '600px',
					width: '100%',
					marginBottom: '150px',
				}}
			>
				<DataTableHeader heading={this.props.heading} list={this.state.list} object={this.props.object} loading={this.state.loading} pageActions={() => this.props.pageActions()} pageControls={this.props.pageControls} lists={this.state.lists} onListChange={(option) => this.onListChange(option)} totalItems={this.state.items.length || 0} totalCount={this.state.totalCount || 0} noFilter={this.props.noFilter} refresh={section => this.refresh(section)} displayResults={option => this.displayResults(option)} />
                {this.props.trail}
				{rows.length > 0 && !this.state.loading? <SLDSDataTable
					columnBordered
					fixedHeader
					fixedLayout
					keyboardNavigation
					items={this.state.items}
					id="DataTableExample-JoinedWithPageHeader"
					joined
					onRowChange={this.handleChanged}
					onSort={this.handleSort}
					selection={this.state.selection}
					selectRows="checkbox"
					hasMore={this.state.hasMore}
					onLoadMore={() => this.handleLoadMore()}
				>
					{rows}
				</SLDSDataTable> : <Noitem content={this.props.noContent} loading={this.state.loading} />}
			</div>
		)
	}
}

export default DataTable;