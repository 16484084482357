import React from 'react';
import SLDSToast from '@salesforce/design-system-react/components/toast';
import ToastContainer from '@salesforce/design-system-react/components/toast/container';

class Toast extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: props.message,
            error: props.error
        }
    }

    componentDidUpdate(pp, ps) {
        if (this.state.message != this.props.message) {
            this.setState({message: this.props.message});
        }
    }

    close() {
        this.setState({message: "", error: ""});
        if (typeof this.props.onClose === "function") {
            this.props.onClose();
        }
    }

    render() {
        let msg = this.state.message;
        if (!msg) {
            return <></>;
        }
        return (
            <ToastContainer>
                <SLDSToast
                    labels={{
                        heading: msg
                    }}
                    variant={this.props.error? "error" : "success"}
                    onRequestClose={() => this.close()}
                />
            </ToastContainer>
        )
    }
}

export default Toast;