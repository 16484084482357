import React from 'react';
import ExpandableSection from '@salesforce/design-system-react/components/expandable-section';
import moment from 'moment/moment';
import storage from '../service/storage';
// thadmin Thabreak9102
class Console extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: ["Welcome to console!"],
            openConsole: storage.fromSession("openConsole") === "false"? false : true
        }
    }
    componentDidMount() {
        let props = this.props;
        if (props.ws) {
            props.ws.mq.consoleNotifications = message => {
                let msg = JSON.parse(message);
                if (msg.group === "notify") {
                    const notification = new Notification(msg.content);
                }
                this.setNotification(msg.content);
            }
        }
    }

    setNotification(notification) {
        let notifications = [notification, ...this.state.notifications];
        this.setState({ notifications, openConsole: true });
    }

    render() {
        return <ExpandableSection id="es-console" title="Console" isOpen={this.state.openConsole}
            onToggleOpen={(event, data) => {
                this.setState({ openConsole: !this.state.openConsole });
                storage.toSession("openConsole", !this.state.openConsole);
            }}>
            <div style={{ backgroundColor: "lightgrey", display: "list-item", height: "70px", color: "darkgreen", padding: "10px", overflowY: "scroll" }}>
                {this.state.notifications.map((noti, idx) => {
                    let msg = noti;
                    if (typeof noti === "object") {
                        msg = noti.content;
                    }
                    return <p key={`console-para-${idx}`}>{moment().format("DD-MM-YYYY HH:mm:ss")} {'>'} {msg}</p>
                })}
            </div>
        </ExpandableSection>
    }
}

export default Console;