import React from 'react';
import QObject from './QObject';

class User extends QObject {

    constructor(data = null, fields = []) {
        let properties = [
            { type: "hidden", name: "_id" },
            { type: "select", label: "Group", name: "group", options: [{ label: "Registered", value: "public", selected: true }, { label: "Admin", value: "admin" }], validations: ["required"] },
            { type: "select", label: "Account Type", name: "actype", options: [{ label: "Personal", value: "personal", selected: true }, { label: "Business", value: "business" }], validations: ["required"] },
            {
                type: "text", label: "Name", name: "name", placeholder: "Only alphabet characters and space are allowed",
                Cell: (row) => {
                    return (
                        <a href={`/admin/Users/edit/user/${row.id}`}>{row.name}</a>
                    )
                }, scope: ['register'], validations: ["required", "alphabet"]
            },
            { type: "email", label: "Email", name: "email", validations: ["email", "required"], placeholder: "xyz@example.com", notes: "We'll never share your email with anyone else.", value: "", unique: true, scope: ['register', "login", "reset"] },
            { type: "tel", label: "Mobile No.", name: "phone", unique: true, placeholder: "9001234567", scope: ['register', 'login'], validations: ["required"], pattern: "[0-9]{3}-[0-9]{2}-[0-9]{3}", prefix: "+91", maxLength: 10 },
            { type: "select", label: "Country", name: "country", options: [{ label: "India", value: "IN" }], hideInList: true },
            { type: "password", label: "Password", name: "password", hideInList: true, scope: ['register', 'login', "newPassword"], validations: ["required"] }
        ];
        super(properties, "User", fields);
        if (data) {
            this.setData(data);
        }
    }

    is(group) {
        let ugroups = this.get("group").value;
        if (ugroups) {
            if (ugroups.indexOf(group) >= 0) {
                return true;
            }
        }
        return false;
    }

    filterElements(scope) {
        if (scope === "login") {
            let elements = this.properties.filter(ele => ele.name != "phone" && ele.name != "email");
            elements.unshift({ type: "text", label: "Phone or Email", name: "phone", validations: ["required"], placeholder: "Phone or Email", notes: "We'll never share your email and phone with anyone else.", value: "", unique: true, scope: ["login"] });
            this.properties = elements;
        }
    }
}

export default User;