import React from 'react';
import auth from '../qrf/service/auth';
import QObject from './QObject';
import Scrip from './Scrip';
import { Link } from 'react-router-dom';

class Trade extends QObject {

    constructor(item = null) {
        let user = auth.currentUser();
        let fg1 = {
            label: "Investments",
            fields: ["maxInvestment", "startingInvestment"]
        }
        let fg2 = {
            label: "Margins",
            fields: ["buymargin", "sellmargin"]
        }

        let properties = [
            { type: "hidden", label: "ID", name: "id" },
            {
                type: "search", object: new Scrip(), label: "Symbol", name: "symbol", required: true,
                Cell: (row) => {
                    return (
                        <Link to={`/view/orders/${row._id}/${row.portfolio._id}/${row.symbol}`}>{row.symbol}</Link>
                    )
                },
                scope: ["listview","formview"],
                validations: ["required"]
            },
            {
                type: "select", label: "BS", name: "buystrategy", options: [{ label: "Buy on Dips", id: "bod" }], scope: ["formview"], selection: item? [{id: item.strategy, label: item.strategy}] : [{ label: "Select", id: "" }]
            },
            {
                type: "select", label: "SS", name: "sellstrategy", options: [{ label: "Sell on Rise", id: "sor" }], scope: ["formview"], selection: item? [{id: item.strategy, label: item.strategy}] : [{ label: "Select", id: "" }]
            },
            { type: "hidden", label: "User", name: "user", value: user._id },
            { type: "hidden", label: "Portfolio", name: "portfolio" },
            { type: "text", label: "Investment", name: "investment", scope: ["listview"], Cell: row => {
                let amt = 0;
                let avgbuy = 0;
                let totalsellshare = 0;
                let totalbuyshare = 0;
                let totalbuyamt = 0;
                row.orders.map(ord => {
                    if (ord.status === "executed" && ord.action === "buy") {
                        totalbuyshare += ord.qty;
                        totalbuyamt += ord.qty * ord.price;
                    }
                    if (ord.status === "executed" && ord.action === "sell") {
                        totalsellshare += ord.qty;
                    }
                });
                avgbuy = (totalbuyamt / totalbuyshare);
                let inv = 0;
                if (avgbuy) {
                    inv = (avgbuy * (totalbuyshare - totalsellshare));
                }
                return inv;
            }},
            { type: "text", label: "Max Investment", name: "maxInvestment", scope: ["listview","formview"] },
            {
                type: "select", label: "Status", name: "status", options: [{ label: "Planned", id: "planned" }, { label: "Proposed", id: "proposed" }, { label: "Executing", id: "executing" }, { label: "Executed", id: "executed" }, { label: "Canceled", id: "canceled" }], scope: ["listview","formview"], selection: item? [{id: item.status, label: item.status}] : [{ label: "Select", id: "" }]
            },
            {
                type: "select", label: "System Status", name: "systemstatus", options: [{ label: "NA", id: "na" }, { label: "Planned", id: "planned" }, { label: "Executing", id: "executing" }, { label: "Executed", id: "executed" }, { label: "Halted", id: "halted" }], scope: ["listview","formview"], selection: item? [{id: item.status, label: item.status}] : [{ label: "NA", id: "na" }]
            },
            {type: "checkbox", name: "autopilot", label: "Auto Pilot", scope: ["listview","formview"], Cell: row => row.autopilot === true? "On" : "Off"},
            { type: "text", label: "Orders", name: "orders", scope: ["listview"], Cell: (row) => {
                return (
                    <Link to={`/view/orders/${row._id}/${row.portfolio._id}/${row.symbol}`}>{row.orders.length}</Link>
                )
            }, editable: false },
            // { type: "text", label: "Starting Investment", name: "startingInvestment", scope: ["listview","formview"], fieldgroup: fg1 },
            // { type: "text", label: "Buy Price", name: "buyPrice", scope: ["listview","formview"], fieldgroup: fg1 },
            // { type: "text", label: "Qty", name: "qty", scope: ["listview","formview"], fieldgroup: fg1 },
            // { type: "text", label: "S1", name: "s1", scope: ["listview","formview"], fieldgroup: fg1 },
            // { type: "text", label: "S2", name: "s2", scope: ["listview","formview"], fieldgroup: fg1 },
            // { type: "text", label: "S3", name: "s3", scope: ["listview","formview"], fieldgroup: fg1 },
            // { type: "text", label: "R1", name: "r1", scope: ["listview","formview"], fieldgroup: fg2 },
            // { type: "text", label: "R2", name: "r2", scope: ["listview","formview"], fieldgroup: fg2 },
            // { type: "text", label: "R3", name: "r3", scope: ["listview","formview"], fieldgroup: fg2 },
            // { type: "text", label: "Accumulator Qty", name: "aqty", scope: ["listview","formview"], fieldgroup: fg2 },
            // { type: "text", label: "BOD Percentage", name: "bodp", scope: ["listview","formview"], fieldgroup: fg2 },
            // { type: "text", label: "SOR Percentage", name: "sorp", scope: ["listview","formview"], fieldgroup: fg2 },
            // { type: "text", label: "Target Price", name: "target", scope: ["listview","formview"], fieldgroup: fg2 },
            // { type: "hidden", label: "Start Time", name: "startTime", scope: ["listview","formview"] },
            {
                name: "actions", type: "icons", label: "Actions",
                values: [
                    {
                        label: "Edit",
                        value: "edit",
                        title: "Edit"
                    },
                    {
                        label: "Delete",
                        value: "delete",
                        title: "Delete"
                    }
                ], 
                scope: ["listview","formview"]
            }
        ];
        super(properties, "Trade");
    }
}

export default Trade;