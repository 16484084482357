import React from 'react';

class Col extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let classes = `slds-col slds-m-around_xx-small slds-size_1-of-1 slds-large-size_${this.props.length}-of-12`; //
        if (this.props.textRight) {
            classes += " qrf-ta-right"
        }
        if (this.props.className) {
            classes += " " + this.props.className;
        }
        return (
            <div className={classes}>
                {this.props.children}
            </div>
        )
    }
}

export default Col;