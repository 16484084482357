import React from 'react';
import Card from '@salesforce/design-system-react/components/card';
import Icon from '@salesforce/design-system-react/components/icon';

class Box extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    onButtonClick(e, btn) {
        e.preventDefault();
        btn.onClick();
    }

    render() {
        let classes = "qrf";
        if (this.props.size === "small") {
            classes += " box small-box"
        }
        if (this.props.border) {
            classes += " border"
        }



        return (
            <Card
                id={this.props.id}
                bodyClassName={this.props.bodyClass ? this.props.bodyClass + " qrf-card-body" : "qrf-card-body"}
                style={{ ...this.props.style }}
                icon={this.props.icon && <Icon category="standard" name="document" size="small" />}
                className={this.props.className + " " + classes}
                hasNoHeader={this.props.noHeader || false}
                headerActions={this.props.headerActions || false}
                header={this.props.header || false}
                heading={!this.props.header && this.props.heading || false}
            >
                {this.props.children}
            </Card>
        )
    }
}

export default Box;