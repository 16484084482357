import React from 'react';
import find from 'lodash.find';
import log from '@salesforce/design-system-react/utilities/log';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Input from '@salesforce/design-system-react/components/input';
import Expression from '@salesforce/design-system-react/components/expression';
import ExpressionGroup from '@salesforce/design-system-react/components/expression/group';
import ExpressionCondition from '@salesforce/design-system-react/components/expression/condition';
import ExpressionFormula from '@salesforce/design-system-react/components/expression/formula';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import DropdownTrigger from '@salesforce/design-system-react/components/menu-dropdown/button-trigger';
import Text from '../element/Text';
import Button from '../element/Button';
import http from '../service/http';
import storage from '../service/storage';

const OperatorsList = [
    { id: 'regex', label: 'Contains' },
    { id: 'eq', label: 'Equals' },
    { id: 'ne', label: 'Not Equals' },
    { id: 'gt', label: 'Greater Than' },
    { id: 'lt', label: 'Less Than' },
    { id: 'in', label: 'In' }
];

class ExpressionBuilder extends React.Component {

    static displayName = 'ExpressionBuilder';

    constructor(props) {
        super(props);
        let lists = props.lists;
        //lists.unshift({label: "Select Filter", value: ""});
        this.state = {
            conditions: [
                {
                    resource: 'company',
                },
            ],
            triggerType: 'all',
            customLogic: undefined,
            lists,
            name: ""
        };
        this.resourcesList = props.object.getProperties().map(prop => { return { id: prop.name, label: prop.label } });
        this.formulaList = [{ id: "toplosers", label: "Top Losers" }];
    }

    static getTriggerType(i, trigger) {
        if (trigger === 'custom') return String(i + 1);
        if (i > 0) {
            if (trigger === 'all') return 'AND';
            if (trigger === 'any') return 'OR';
        }
        return '';
    }

    updateData(i, val, type) {
        const { conditions } = this.state;
        if (type === 'value') conditions[i].value = val;
        else conditions[i][type] = val.selection[0].id;
        this.setState({ conditions });
    }

    updateSubData(i, j, val, type) {
        const { conditions } = this.state;
        if (type === 'value') conditions[i].conditions[j].value = val;
        else conditions[i].conditions[j][type] = val.selection[0].id;
        this.setState({ conditions });
    }

    updateFormula(data, type) {
        const { conditions } = this.state;
        conditions[type] = data;
        this.setState({ conditions });
    }

    addCondition() {
        const { conditions } = this.state;
        const newCondition = {
            isGroup: false,
            resource: '',
            operator: '',
            value: '',
        };
        conditions.push(newCondition);
        this.setState({ conditions });
    }

    addSubCondition(i) {
        const { conditions } = this.state;
        const newCondition = {
            resource: '',
            operator: '',
            value: '',
        };
        conditions[i].conditions.push(newCondition);
        this.setState({ conditions });
    }

    deleteCondition(i) {
        const { conditions } = this.state;
        if (conditions.length > 1) {
            conditions.splice(i, 1);
            this.setState({ conditions });
        } else {
            const newCondition = {
                resource: '',
                operator: '',
                value: '',
            };
            this.setState({ conditions: [newCondition] });
        }
    }

    deleteSubCondition(i, j) {
        const { conditions } = this.state;
        if (conditions[i].conditions.length > 1) {
            conditions[i].conditions.splice(j, 1);
            this.setState({ conditions });
        } else {
            conditions.splice(i, 1);
            this.setState({ conditions });
        }
    }

    addGroup() {
        if (!this.props.isChild) {
            const { conditions } = this.state;
            const newCondition = {
                resource: '',
                operator: '',
                value: '',
            };
            const newGroup = {
                isGroup: true,
                triggerType: 'all',
                conditions: [newCondition],
            };
            conditions.push(newGroup);
            this.setState({ conditions });
        }
    }

    updateGroupData(i, val, type) {
        const { conditions } = this.state;
        conditions[i][type] = val;
        this.setState({ conditions });
    }

    getConditionValue(condition) {
        return condition.value;
    }

    saveFilter(e) {
        const { conditions, name } = this.state;
        if (conditions) {
            let userObj = storage.getUser();
            let user = userObj.get("_id").value
            if (user) {
                this.setState({loading: true});
                let id = this.state.list && this.state.list.id || undefined;
                let item = {conditions, label: name, user, id, triggerType: this.state.triggerType};
                http.post('scrip/save/filter', {item}).then(res => {
                    this.setState({loading: false});
                    if (res.status) {
                        if (this.props.events) {
                            this.props.events.afterSave();
                        }
                    }
                })
            }
        }
    }

    loadFilter(id) {
        if (id) {
            http.post('scrip/get/filter', {id}).then(res => {
                if (res.status) {
                    this.setState({list: res.data, conditions: res.data.conditions, name: res.data.label});
                }
            })
        }
    }

    onListChange(list) {
        this.setState({list});
        this.loadFilter(list.id);
    }

    render() {
        return (
            <>
                <Dropdown
                    align="right"
					iconCategory="utility"
					iconName="down"
					iconPosition="right"
					label={this.state.list && this.state.list.label || "Select Filter"}
                    options={this.state.lists}
                    onSelect={option => this.onListChange(option)}
                />
                <Text name="name" value={this.state.name} onChange={val => this.setState({name: val})} placeholder="My filter list #1" label="Save As" />
                <Expression
                    labels={{title: ""}}
                    id="expression-example"
                    events={{
                        onChangeTrigger: (e, data) => {
                            log({
                                action: this.props.action,
                                e,
                                eventName: 'Trigger Changed',
                                data,
                            });
                            this.setState({ triggerType: data.triggerType });
                        },
                        onChangeCustomLogicValue: (e, data) => {
                            log({
                                action: this.props.action,
                                e,
                                eventName: 'Custom Logic Changed',
                                data,
                            });
                            this.setState({ customLogic: data.value });
                        },
                        onAddCondition: (e) => {
                            log({
                                action: this.props.action,
                                e,
                                eventName: 'New Condition Added',
                                data: null,
                            });
                            this.addCondition();
                        },
                        onAddGroup: (e) => {
                            log({
                                action: this.props.action,
                                e,
                                eventName: 'New Group Added',
                                data: null,
                            });
                            this.addGroup();
                        },
                    }}
                    triggerType={this.state.triggerType}
                    customLogicValue={this.state.customLogic}
                >
                    {this.state.triggerType === 'formula' ? (
                        <ExpressionFormula
                            id="expression-formula"
                            resourceCombobox={
                                <Combobox
                                    labels={{
                                        placeholder: 'Insert a Resource',
                                    }}
                                    id="expression-formula-insert-resource"
                                    multiple={false}
                                    options={this.resourcesList}
                                    selection={
                                        this.state.conditions.resource
                                            ? [
                                                find(this.resourcesList, {
                                                    id: this.state.conditions.resource,
                                                }),
                                            ]
                                            : []
                                    }
                                    events={{
                                        onSelect: (e, data) => {
                                            this.updateFormula(data.selection[0].id, 'resource');
                                            log({
                                                action: this.props.action,
                                                e,
                                                eventName: `Formula Resource Changed`,
                                                data,
                                            });
                                        },
                                    }}
                                    variant="inline-listbox"
                                />
                            }
                            events={{
                                onChangeTextEditor: (e, data) =>
                                    log({
                                        action: this.props.action,
                                        e,
                                        eventName: `Formula updated in Text Editor`,
                                        data,
                                    }),
                                onClickCheckSyntax: (e) =>
                                    log({
                                        action: this.props.action,
                                        e,
                                        eventName: `Check Syntax Button Clicked`,
                                        data: null,
                                    }),
                                onClickHelp: (e) =>
                                    log({
                                        action: this.props.action,
                                        e,
                                        eventName: `Get Help Button Clicked`,
                                        data: null,
                                    }),
                            }}
                            functionCombobox={
                                <Combobox
                                    labels={{
                                        placeholder: 'Insert a Function',
                                    }}
                                    id="expression-formula-insert-function"
                                    options={this.formulaList}
                                    selection={
                                        this.state.conditions.function
                                            ? [
                                                find(this.resourcesList, {
                                                    id: this.state.conditions.function,
                                                }),
                                            ]
                                            : []
                                    }
                                    events={{
                                        onSelect: (e, data) => {
                                            this.updateFormula(data.selection[0].id, 'function');
                                            log({
                                                action: this.props.action,
                                                e,
                                                eventName: `Formula Function Changed`,
                                                data,
                                            });
                                        },
                                    }}
                                    variant="inline-listbox"
                                />
                            }
                            operatorInput={
                                <Input
                                    assistiveText={{ label: 'Insert a Operator' }}
                                    id="insert-operator-formula"
                                    placeholder="Insert a Operator"
                                />
                            }
                        />
                    ) : (
                        this.state.conditions.map((condition, i) =>
                            !condition.isGroup ? (
                                <ExpressionCondition
                                    focusOnMount
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    key={i}
                                    id={`expression-condition-${i}`}
                                    labels={{
                                        label: ExpressionBuilder.getTriggerType(i, this.state.triggerType),
                                    }}
                                    events={{
                                        onChangeOperator: (e, obj) => {
                                            log({
                                                action: this.props.action,
                                                e,
                                                eventName: `Condition ${i} Operator Changed`,
                                                data: obj,
                                            });
                                            this.updateData(i, obj, 'operator');
                                        },
                                        onChangeResource: (e, obj) => {
                                            log({
                                                action: this.props.action,
                                                e,
                                                eventName: `Condition ${i} Resource Changed`,
                                                data: obj,
                                            });
                                            this.updateData(i, obj, 'resource');
                                        },
                                        onChangeValue: (e, data) => {
                                            log({
                                                action: this.props.action,
                                                e,
                                                eventName: `Condition ${i} Value Changed`,
                                                data,
                                            });
                                            this.updateData(i, data.value, 'value');
                                        },
                                        onDelete: (e) => {
                                            log({
                                                action: this.props.action,
                                                e,
                                                eventName: `Condition ${i} Deleted`,
                                                data: null,
                                            });
                                            this.deleteCondition(i);
                                        },
                                    }}
                                    resourcesList={this.resourcesList}
                                    resourceSelected={find(this.resourcesList, {
                                        id: condition.resource,
                                    })}
                                    operatorsList={OperatorsList}
                                    operatorSelected={find(OperatorsList, {
                                        id: condition.operator,
                                    })}
                                    value={this.getConditionValue(condition)}
                                />
                            ) : (
                                <ExpressionGroup
                                    focusOnMount
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    key={i}
                                    id={`expression-group-${i}`}
                                    labels={{
                                        label: ExpressionBuilder.getTriggerType(i, this.state.triggerType),
                                    }}
                                    events={{
                                        onChangeCustomLogicValue: (e, data) => {
                                            log({
                                                action: this.props.action,
                                                e,
                                                eventName: `Custom Logic Value of Condition Group ${i} Changed`,
                                                data,
                                            });
                                            this.updateGroupData(i, data.value, 'customLogic');
                                        },
                                        onChangeTrigger: (e, data) => {
                                            log({
                                                action: this.props.action,
                                                e,
                                                eventName: `Trigger of Condition Group ${i} Changed`,
                                                data,
                                            });
                                            this.updateGroupData(i, data.triggerType, 'triggerType');
                                        },
                                        onAddCondition: (e) => {
                                            log({
                                                action: this.props.action,
                                                e,
                                                eventName: `New Condition added to Condition Group ${i}`,
                                                data: null,
                                            });
                                            this.addSubCondition(i);
                                        },
                                    }}
                                    customLogicValue={condition.customLogic}
                                    triggerType={condition.triggerType}
                                >
                                    {condition.triggerType === 'formula' ? (
                                        <ExpressionFormula
                                            id={`expression-group-${i}-formula`}
                                            resourceCombobox={
                                                <Combobox
                                                    labels={{
                                                        placeholder: 'Insert a Resource',
                                                    }}
                                                    id={`expression-group-${i}-formula-resource`}
                                                    options={this.resourcesList}
                                                    variant="inline-listbox"
                                                />
                                            }
                                            events={{
                                                onClickCheckSyntax: log({
                                                    action: this.props.action,
                                                    eventName: `Check Syntax Button Clicked`,
                                                    data: null,
                                                }),
                                                onClickHelp: log({
                                                    action: this.props.action,
                                                    eventName: `Get Help Button Clicked`,
                                                    data: null,
                                                }),
                                            }}
                                            functionCombobox={
                                                <Combobox
                                                    labels={{
                                                        placeholder: 'Insert a Function',
                                                    }}
                                                    id={`expression-group-${i}-formula-function`}
                                                    options={this.formulaList}
                                                    variant="inline-listbox"
                                                />
                                            }
                                            operatorInput={
                                                <Input
                                                    assistiveText={{ label: 'Insert a Operator' }}
                                                    id={`insert-operator-formula-${i}`}
                                                    placeholder="Insert a Operator"
                                                />
                                            }
                                        />
                                    ) : (
                                        condition.conditions.map((c, j) => (
                                            <ExpressionCondition
                                                focusOnMount
                                                /* eslint-disable-next-line react/no-array-index-key */
                                                key={j}
                                                id={`expression-group-${i}-condition-${j}`}
                                                isSubCondition
                                                labels={{
                                                    label: ExpressionBuilder.getTriggerType(
                                                        j,
                                                        condition.triggerType
                                                    ),
                                                }}
                                                events={{
                                                    onChangeOperator: (e, obj) => {
                                                        log({
                                                            action: this.props.action,
                                                            e,
                                                            eventName: `Operator of Condition ${j} of Group ${i} Changed`,
                                                            data: obj,
                                                        });
                                                        this.updateSubData(i, j, obj, 'operator');
                                                    },
                                                    onChangeResource: (e, obj) => {
                                                        log({
                                                            action: this.props.action,
                                                            e,
                                                            eventName: `Resource of Condition [${i},${j}] Changed`,
                                                            data: obj,
                                                        });
                                                        this.updateSubData(i, j, obj, 'resource');
                                                    },
                                                    onChangeValue: (e, data) => {
                                                        log({
                                                            action: this.props.action,
                                                            e,
                                                            eventName: `Value of Condition [${i},${j}] Changed`,
                                                            data,
                                                        });
                                                        this.updateSubData(i, j, data.value, 'value');
                                                    },
                                                    onDelete: (e) => {
                                                        log({
                                                            action: this.props.action,
                                                            e,
                                                            eventName: `Condition [${i},${j}] deleted`,
                                                            data: null,
                                                        });
                                                        this.deleteSubCondition(i, j);
                                                    },
                                                }}
                                                resourcesList={this.resourcesList}
                                                resourceSelected={find(this.resourcesList, {
                                                    id: c.resource,
                                                })}
                                                operatorsList={OperatorsList}
                                                operatorSelected={find(OperatorsList, {
                                                    id: c.operator,
                                                })}
                                                value={this.getConditionValue(c)}
                                            />
                                        ))
                                    )}
                                </ExpressionGroup>
                            )
                        )
                    )}
                </Expression>
                <Button onClick={e => this.saveFilter(e)} label="Save" />
            </>
        );
    }
}

export default ExpressionBuilder