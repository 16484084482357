import React from 'react';
import Pill from '@salesforce/design-system-react/components/pill';
import Icon from '@salesforce/design-system-react/components/icon';
import Avatar from '@salesforce/design-system-react/components/avatar';
import Text from '../element/Text';

class Pills extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pills: props.pills || []
        }
    }

    onClick(event) {
		this.props.action('onClick')(event);
	};

	onRemove(event, pill) {
		//this.props.action('onRemove')(event);
        let pills = [...this.state.pills]
        pills = pills.filter(spill => spill !== pill)
		this.setState({pills});
        this.props.onChange(pills);
	};

    setPill(e) {
        let pills = [...this.state.pills]
        pills.push(e.target.value)
        this.setState({pills})
        e.target.value = "";
        this.props.onChange(pills)
    }

    render() {
        return (
            <div className="slds-grid slds-grid_pull-padded-medium">
                <div className="slds-p-horizontal_medium">
                    <Text onEnter={e => this.setPill(e)} placeholder={this.props.placeholder} />
                    {this.state.pills.map(pill => {
                        return <Pill
                            labels={{
                                label: pill || "",
                                title: pill || "",
                                removeTitle: 'Remove',
                            }}
                            icon={
                                pill.icon && <Icon title={pill.title || ""} category="standard" name={pill.icon || ""} />
                            }
                            onClick={this.onClick}
                            onRemove={(event) => this.onRemove(event, pill)}
                        />
                    })
                    }
                </div>
            </div>
        )
    }
}

export default Pills;