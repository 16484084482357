import Wishlists from "../modules/Stockist/Wishlists"

export const config = {
    STORAGE_PREFIX: "qrf_"
}
export function getModuleMenus() {
    let menus = [
        { title: "My Wishlist", link: "/mod/stockist/wishlist", component: Wishlists }
    ]
    return menus
}
