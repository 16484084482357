import React from 'react';
import moment from 'moment';
import { INDICES } from '../constants';
import QObject from './QObject';
import { Link } from 'react-router-dom';
import { moveIcon } from '../components/scrip/ScripCommon';
import storage from '../qrf/service/storage';
import { tsToDate } from '../config';

const getClass = (prcnt) => {
    let classname = '';
    if (prcnt >= 200) {
        classname = 'slds-theme_warning'; //{color: "blue", fontWeight: "bold"};
    } else if (prcnt >= 100) {
        classname = 'slds-theme_success'; //{color: "green", fontWeight: "bold"};
    } else if (prcnt <= 100) {
        classname = 'slds-badge_inverse'; //{color: "lightgrey"};
    }
    return classname;
}

class Scrip extends QObject {

    constructor(properties = [], objectName = "Scrip") {
        let props = [
            {
                name: "company", type: "text", label: "Company",
                Cell: row => {
                    let company = row.company && row.company.replace("Ltd.", "").replace("Limited", "");
                    let textDecoration  = row.ltpError? "line-through" : "none";
                    return (
                        <span style={{textDecoration}}><Link title={row.ltpError? "LTP not up to date" : row.company} to={`/view/scrip/${row.symbol}`}>{company? company.trim() : row.symbol} {this.getIcon(row)} {row.percentage}% - {row.ltp}</Link></span>
                    )
                },
                width: "25rem",
                group: "bi",
                scope: ["bi", "listview", "searchable"]
            },
            { name: "ltpUpdated", type: "text", label: "LTP Updated", scope: ["bi", "listview"], Cell: row => {
                if (row.ltpUpdated) {
                    return tsToDate(row.ltpUpdated);
                }
                return "--"
            }},
            { name: "symbol", type: "text", label: "Symbol", scope: ["bi", "searchable", "listview"] },
            { name: "ltpError", type: "text", label: "LTP Error", scope: ["bi", "searchable"] },
            {
                name: "indices", type: "select", label: "Indices", options: Object.keys(INDICES).map(k => {
                    return { label: INDICES[k], value: k }
                }), Cell: val => {
                    return val && val.join(", ") || ""
                }, scope: ["bi"], multiple: true
            },
            { name: "industry", type: "text", label: "Industry", width: "10rem", scope: ["bi"] },
            { name: "token", type: "text", label: "Token", scope: ["bi"] },
            { name: "angeltoken", type: "text", label: "Angel Token", scope: ["bi"] },
            { name: "group", type: "text", label: "Group", group: "bi" },
            { name: "facevalue", type: "text", label: "Face Value" },
            { name: "exchange", type: "select", options: [{ label: "NSE", value: "nse" }, { label: "BSE", value: "bse" }], label: "Exchange", Cell: val => val.toString(), scope: ["admin"], multiple: true },
            { name: "isin", type: "text", label: "ISIN", group: "bi", scope: ["searchable"] },
            { name: "instrument", type: "text", label: "Instrument", group: "bi" },
            { name: "sector", type: "text", label: "Sector", group: "bi" },
            { name: "ltp", type: "text", label: "LTP", sortable: true, scope: ["ti", "listview"] },
            { name: "move", type: "text", label: "Move", sortable: true, scope: ["ti"], Cell: row => this.getIcon(row) },
            { name: "percentage", type: "text", label: "Percentage", sortable: true, scope: ["ti", "listview"] },
            { name: "points", type: "text", label: "Points", scope: ["ti"] },
            { name: "open", type: "text", label: "Open", scope: ["ti"] },
            { name: "close", type: "text", label: "Close", scope: ["ti"] },
            { name: "low", type: "text", label: "Low", scope: ["ti"] },
            { name: "high", type: "text", label: "High", scope: ["ti"] },
            { name: "yclose", type: "text", label: "Yclose", scope: ["ti"] },
            { name: "industry_new_name", type: "text", label: "Ind. New Name", scope: ["admin"] },
            { name: "size", type: "select", label: "Size", options: [{ value: "large", label: "Large Cap" }, { value: "mid", label: "Mid Cap" }, { value: "small", label: "Small Cap" }, { value: "micro", label: "Micro Cap" }], group: "bi" },
            { name: "cocode", type: "text", label: "CoCode", scope: ["ti"] },
            {
                name: "updatedAt", type: "text", label: "Updated", Cell: row => {
                    return row && row.updatedAt && moment(row.updatedAt).format("DD-MM-YY") || ""
                }
            },
            {
                name: "oyr", type: "text", label: "OYR", Cell: row => {
                    let prcnt = row.percent || (row.oyr && row.oyr.percent) || "0";
                    return (<u className={getClass(prcnt)}>{prcnt}</u>)
                },
                sortable: () => {
                    return "oyr.percent";
                },
                group: "ti",

                getValue: value => 0
            },
            {
                name: "fyr", type: "text", label: "FYR", Cell: row => {
                    let prcnt = row.percent || (row.fyr && row.fyr.percent) || "0";
                    return (<u className={getClass(prcnt)}>{prcnt}</u>)
                },
                sortable: () => {
                    return "oyr.percent";
                },
                group: "ti",

                getValue: value => 0
            },
            {
                name: "odr", type: "text", label: "ODR", Cell: row => {
                    let prcnt = row.percent || (row.fyr && row.fyr.percent) || "0";
                    return (<u className={getClass(prcnt)}>{prcnt}</u>)
                },
                sortable: () => {
                    return "oyr.percent";
                },
                group: "ti",

                getValue: value => 0
            },
            {
                name: "actions", type: "icons", label: "Actions",
                values: [
                    {
                        label: "T",
                        value: "trade",
                        title: "Trade"
                    },
                    {
                        label: "R",
                        value: "radar",
                        icon: "anywhere_alert",
                        title: "Add to Radar"
                    },
                    {
                        label: "W",
                        value: "atwl",
                        title: "Add to Watchlist"
                    }
                ],
                width: "12rem",
                scope: ["admin", "listview"]
            },
            { name: "tags", type: "multiselect", label: "Tags", scope: [storage.getUser().is("admin") ? "ti" : ""], subtype: "pills", pills: [], placeholder: "Type here to add new tag" },
            { name: "category", type: "select", options: [{ label: "Average", value: "avarage" }, { label: "Good", value: "good" }, { label: "Bluechip", value: "bluechip" }], label: "Category", scope: ["ti"] }
        ];
        if (properties.length) {
            props = props.concat(properties);
        }
        super(props, objectName);
        this.groups = {
            bi: "Basic Info",
            ti: "Trading Info"
        }
    }

    getIcon(row) {
        return moveIcon(row.move)
    }

    getActions(ele) {
        let actions = ele.values;
        let user = storage.getUser();
        let ug = user.get("group").value;
        if (ug === "public") {
            actions = actions.filter(a => {
                return a.scope === "public"
            });
            ele.actions = actions;
        }
        return ele;
    }
}

export default Scrip;