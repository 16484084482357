import React from 'react';
import SCheckbox from '@salesforce/design-system-react/components/checkbox';

class Checkbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    handleChange(e) {
        this.props.onChange(e.target.checked);
    }

    render() {
        return (
            <SCheckbox
                labels={{
                    label: this.props.label,
                    toggleDisabled: "",
                    toggleEnabled: "",
                }}
                onChange={e => this.handleChange(e)}
                className={this.props.className}
                variant={this.props.variant || 'base'}
                checked={this.props.value}
            />
        )
    }
}

export default Checkbox;