import { isExpired, decodeToken } from "react-jwt";
import storage from "./storage";

const auth = {
    currentUser: () => {
        let token = storage.fromSession("token");
        return !isExpired(token) && decodeToken(token).user;
    },
    isAdmin: () => {
        let user = auth.currentUser();
        // if (user.groups && Qarray.contains(user.groups, "alias", "admin")) {
        //     return true;
        // }
        return false;
    },
    isLoggedIn: (username) => {
        let user = auth.currentUser();
        if (user && user.username === username) {
            return true;
        }
        return false;
    }
}
export default auth;