import React from 'react';
import BreadCrumb from '@salesforce/design-system-react/components/breadcrumb';
import { Link } from 'react-router-dom';

function Crumb(props) {
    let elements = props.links;
    const trail = [];
    elements.map(ele => {
        let anchor = ele.split(":");
        trail.push(<Link id="parent-entity" to={`${anchor[1]}`}>
        {`${anchor[0]}`}
    </Link>)
    });
    return <BreadCrumb
        assistiveText={{ label: 'Breadcrumb' }}
        trail={trail}
    />
}

export default Crumb;