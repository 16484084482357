import React from 'react';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import storage from '../service/storage';

class Buttons extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            item: props.item
        }
    }

    onClick(e, btn) {
        if (typeof btn.onClick === "function") {
            btn.onClick(btn);
        } else {
            this.props.onClick(btn);
        }
    }

    renderDropdown(items) {
        if (items && items.length > 0) {
            return <Dropdown
                assistiveText={{ icon: 'More Options' }}
                buttonVariant="icon"
                iconCategory="utility"
                iconName="down"
                iconVariant="border-filled"
                onSelect={(item) => {
                    this.onClick(null, item)
                }}
                openOn="click"
                options={items}
                align="right"
            />
        }
        return <></>
    }

    render() {
        let variant = this.props.variant || "standard";
        let ug = storage.getUser().get("group").value;
        let actions = this.props.actions && this.props.actions.filter(action => {
            if ((action.scope && ug && !ug.includes(action.scope))) {
                return false;
            } else {
                return true;
            }
        });
        let firstThree = [];
        let afterThree = [];
        if (variant != "dropdown") {
            firstThree = actions.slice(0, 3);
            if (actions.length > 3) {
                afterThree = actions.slice(3) || []
            }
        } else {
            firstThree = actions.slice(0, 1);
            afterThree = actions.slice(1) || []
        }
        let firstThreeButtons = [];
        if (this.props.icons) {
            firstThree.map((btn, idx) => {
                let lbl = typeof btn.label === "function" ? btn.label(this.state.item) : btn.label;
                firstThreeButtons.push(<Button
                    key={`btn-grp-btn-${idx}`}
                    label={lbl}
                    onClick={e => this.onClick(e, btn)}
                    title={btn.title}
                    iconCategory="utility"
                    iconName={btn.icon || ""}
                    iconPosition="left"
                />
                );
            })
        } else {
            firstThree.map((btn, idx) => {
                let lbl = typeof btn.label === "function" ? btn.label(this.state.item) : btn.label;
                firstThreeButtons.push(<Button
                    key={`btn-grp-btn-${idx}`}
                    label={lbl}
                    assistiveText={{ icon: lbl }}
                    onClick={e => this.onClick(e, btn)}
                    size="x-small"
                    title={btn.title}
                />
                );
            })
        }
        if (this.props.icons) {
            return <div className="slds-x-small-buttons_horizontal">
                {firstThreeButtons}
                {this.renderDropdown(afterThree)}
            </div>
        }
        return <ButtonGroup id="button-group-icon-group-1">
            {firstThreeButtons}
            {this.renderDropdown(afterThree)}
        </ButtonGroup>
    }
}

export default Buttons;