import React from 'react';
import Header from '../../components/common/Header';
import AuthContext from '../AuthContext';
import Console from '../view/Console';
import { Copyright } from '../view/Copyright';
import storage from '../service/storage';

class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        storage.remove("list");
    }

    render() {
        return <>
            <AuthContext.Consumer>
                {(context) => {
                    if (this.props.user && !context.authenticated) {
                        return context.login();
                    } else {
                        let elements = [<Header />];
                        this.props.elements.map((element, idx) => {
                            elements.push(<element.type key={`layout-ele-${idx}`} {...element.props} {...this.props} {...context}>
                                {element.props.children}
                            </element.type>)
                        })
                        //elements.push(<Console {...context} />);
                        return elements;
                    }
                }}
            </AuthContext.Consumer>
            <Copyright />
        </>
    }
}

export default Layout;