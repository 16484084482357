import moment from 'moment';
import React from 'react';
import { DATE_TIME_FORMAT } from '../../config';
import http from '../../qrf/service/http';
import storage from '../../qrf/service/storage';
import Icon from '@salesforce/design-system-react/components/icon';
import Modal from '../../qrf/view/Modal';

export function loadLists() {
    http.post(this.state.object.getApi + '/lists', { user: storage.getUserId() }).then(res => {
        if (res.status) {
            let lists = res.data.items || [];
            lists.unshift({ label: "All", value: "" });
            this.setState({ lists }, () => {
                if (typeof this.props.onListsLoaded === "function") {
                    this.props.onListsLoaded(lists);
                }
            });
        }
    })
}

export function loadList(page = 1, list = "", key = "items") {
    let apiUrl = this.state.object.getApi;
    let filter = { ...this.state.filter }
    Object.keys(filter).forEach((k) => filter[k] == "" && delete filter[k]);
    let data = { filter, page, ...this.state.sort, search: this.state.search }
    if (!list) {
        list = this.state.list;
    }
    if (list) {
        apiUrl += "/list";
        data.list = list;
    }
    if (list === "items") {
        data.oid = this.state.oid;
    }
    this.setState({ loading: true });
    this.isLoading = false;
    let lists = this.state.lists;
    data.limit = this.state.limit || 50;
    if (this.state.id) {
        data.id = this.state.id;
    }
    http.post(apiUrl, data).then(res => {
        this.setState({ loading: false });
        if (res.status) {
            setItems.bind(this)(res, lists, key);
        } else {
            //
        }
    })
}

export function toSimilar(items) {
    let similar = [];
    items.map(item => {
        similar.push({
            id: item._id,
            symbol: item.symbol,
            angeltoken: item.angeltoken,
            label: item.company,
            topRightText: item.ltp,
            bottomLeftText: item.sector || item.industry || item.industry_new_name,
            bottomRightText: moveIcon(item.move) || "",
        })
    })
    return similar;
}

export function addToRadar(item) {
    let user = storage.getUser();
    this.setState({ loading: true });
    if (user && item) {
        let scrip = item.symbolname || item.symbol;
        http.post('radar/add', { scrip, user: user.get("_id").value }).then(res => {
            this.setState({ loading: false });
            if (res.status) {
                alert("Stock added to your radar");
            }
        })
    }
}

export function updateLtp(radar = false, callback = null, filter2 = null, auto = false) {
    //tosti("ok");
    if (auto) {
        console.log("checking auto update ltp");
        let autoLtp = storage.fromSession("autoLtp");
        if (autoLtp) {
            let diff = moment().diff(moment(autoLtp), "minutes");
            if (diff >= 5) {
                console.log("auto updating ltp");
                autoLtp = moment().toLocaleString();
                storage.toSession("autoLtp", autoLtp);
            } else {
                console.log("returning auto update ltp: " + diff);
                return;
            }
        } else {
            autoLtp = moment().toLocaleString();
            storage.toSession("autoLtp", autoLtp);
        }
    }
    let user = storage.getUser().get("_id").value;
    this.setState({ updatingLtp: "1" });
    storage.toSession("updatingLtp", 1);
    let filter = getStateFilters(this, filter2);
    http.post("scrip/updateLtp", { filter, radar, user }).then(res => {
        storage.toSession("updatingLtp", 0);
        if (res.status) {
            let slogs = this.state.logs || [];
            let logs = [...slogs];
            logs.push(<p>Last Updated at {moment().format(DATE_TIME_FORMAT)}</p>);
            this.setState({ logs }, () => {
                if (typeof callback === "function") {
                    callback();
                }
            });
        }
    });
}

export function getStateFilters(_this, filter2) {
    let filter = _this.state.filter;
    if (filter2) {
        filter = { ...filter, ...filter2 };
    }
    if (_this.state.search) {
        filter = { ...filter, ..._this.state.search };
    }
    if (_this.state.selection && _this.state.selection.length > 0) {
        filter = {symbol: {$in: _this.state.selection.map(ele => ele.symbol)}};
    }

    return filter;
}

export function renderModal(modals = {}) {
    console.log();
    return Object.keys(modals).map(key => {
        let modal = modals[key];
        return modal.open && <Modal size="small" heading={modal.heading} open={modal.open} onClose={e => modal.onClose(e)} onSubmit={e => modal.onSubmit()} submitText={modal.submitText}>
            {modal.elements}
        </Modal>
    });
}

export function onAction(action, item, callback = null) {
    if (action.scope && !storage.getUser().is(action.scope)) {
        if (storage.getUserId()) {
            alert(`Only users with ${action.scope} access can perform this operation`);
        } else {
            window.location.href = "/page/login";
        }
    }
    switch (action.value) {
        case "radar":
            this.addToRadar(item);
            break;
        case "uc":
            this.updateReturns([item]);
            break;
        case "ltp":
            this.updateLtp(false, callback, { symbol: item.symbol });
            break;
        case "trade":
        case "edit":
            this.setState({ newModal: action.value, item });
            break;
        case "delete":
            archive.bind(this)(item);
            break;
    }
}

export function updateReturns(items = []) {
    let filter = {};
    if (items.length) {
        let tokens = items.map(item => item.angeltoken);
        filter = { angeltoken: { $in: tokens } };
        this.setState({ loading: true });
        http.post("scrip/updateReturns", { filter }).then(res => {
            if (res.status) {
                let slogs = this.state.logs || [];
                let logs = [...slogs];
                logs.push(<p>Last Updated at {moment().format(DATE_TIME_FORMAT)}</p>);
                this.setState({ logs });
            }
        })
    }
}

export function moveIcon(dir) {
    return dir === "up" && <Icon
        assistiveText={{ label: 'Up' }}
        category="utility"
        name="up"
        size="small"
        colorVariant="success"
    /> || <Icon
            assistiveText={{ label: 'Down' }}
            category="utility"
            name="down"
            size="small"
            colorVariant="error"
        />
}

export function scan() {
    let {dtrend, wtrend} = this.state;
    let cidx = this.state.curTab || 0;
    let ctab = this.state.tabs[cidx];
    let index = ctab.limit;
    let filter = { indices: index }; //{indices: {$ne: null}}; //{angeltoken: {$in: [157, 13]}}; //{angeltoken: 157};
    this.setState({ loading: true });
    let user = storage.getUserId();
    http.post("scrip/scan", { filter, user, dtrend, wtrend, includeToday: this.state.includeToday }).then(res => {
        if (res.status) {
            let slogs = this.state.logs || [];
            let logs = [...slogs];
            logs.push(<p>Last Updated at {moment().format(DATE_TIME_FORMAT)}</p>);
            this.setState({ logs });
        }
    })
}

export function portfolio() {
    this.setState({ loading: true });
    let user = storage.getUserId();
    http.post("portfolio/get", { user }).then(res => {
        if (res.status) {
            let slogs = this.state.logs || [];
            let logs = [...slogs];
            logs.push(<p>Last Updated at {moment().format(DATE_TIME_FORMAT)}</p>);
            this.setState({ logs });
        }
    })
}

export function updateIndices() {
    this.setState({ loading: true });
    http.post("scrip/updateIndices").then(res => {
        if (res.status) {
            let slogs = this.state.logs || [];
            let logs = [...slogs];
            logs.push(<p>Last Updated at {moment().format(DATE_TIME_FORMAT)}</p>);
            this.setState({ logs });
        }
    })
}

export function checkIndices() {
    this.setState({ loading: true });
    http.post("scrip/checkIndices").then(res => {
        if (res.status) {
            let slogs = this.state.logs || [];
            let logs = [...slogs];
            logs.push(<p>Last Updated at {moment().format(DATE_TIME_FORMAT)}</p>);
            this.setState({ logs });
        }
    })
}

export function setItems(res, lists, key) {
    if (res && res.data && res.data.items) {
        let items = res.data.items;
        let cols = this.state.cols;
        if (this.state.object.has("items")) {
            let oi = this.state.object.get("items", items);
            items = oi.items;
            lists = oi.lists;
            cols = oi.cols;
            // if (lists.length) {
            //     items = items[0];
            // }
        }
        if (this.props.pagination === false) {
            this.setState({ [key]: items, totalCount: items.length, cols });
        } else {
            let allItems = this.state[key].concat(items);
            this.setState({ [key]: allItems, hasMore: allItems.length < res.data.totalCount, page: res.data.page, cols, totalCount: res.data.totalCount }, () => {
                this.isLoading = false;
            })
        }
    }
}

export function checkReturns(e) {
    this.setState({ loading: true });
    let filter = this.state.returnFilter && JSON.parse(this.state.returnFilter);
    let {lastTradingSession} = this.state;
    if (lastTradingSession) {
        lastTradingSession = moment(lastTradingSession).format("YYYY-MM-DD") + "T00:00:00+05:30";
    }
    http.post("scrip/checkReturns", { filter, lastTradingSession }).then(res => {
        if (res.status) {
            this.setState({ rcMessage: res.message, loading: false });
        }
    })
}

export function newPortfolio() {
    this.setState({ loading: true });
    let user = storage.getUserId();
    http.post("portfolio/get", { user }).then(res => {
        if (res.status) {
            let slogs = this.state.logs || [];
            let logs = [...slogs];
            logs.push(<p>Last Updated at {moment().format(DATE_TIME_FORMAT)}</p>);
            this.setState({ logs });
        }
    })
}

export function getScrip(symbol) {
    this.setState({ loading: true });
    let user = storage.getUserId();
    if (user) {
        return http.post("scrip/get/detail", { symbol });
    }
    return new Promise(resolve => {
        resolve({res: {status: false}})
    }) 
}

export function alias(label) {
    return label.toLowerCase().replaceAll(" ", "-");
}

export function updateYClose() {
    let filter = getStateFilters(this);
    http.post("scrip/updateYClose", { filter }).then(res => {
        if (res.status) {
            
        }
    });
}

export function buy() {

}

export function archive(item) {
    let id = item._id;
    if (this.state.alias === "radar") {
        id = item.symbol;
    }
    http.post(this.state.object.deleteApi, {id}).then(res => {
        if (res.status) {
            this.setState({reloadTab: !this.state.reloadTab});
        }
    })
}

export function isTradingOn() {
    
}