import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Loader from '../qrf/view/Loader';
import Profile from './user/Profile';
import Trades from './user/Trades';
import {TradingViewChart} from './Chart';
import Charts from './Charts';
import Orders from './user/Orders';
const Layout = React.lazy(() => import('../qrf/grid/Layout'));
const Screen = React.lazy(() => import('../qrf/grid/Screen'));
const Header = React.lazy(() => import('./common/Header'));
const Login = React.lazy(() => import('./user/Login'));
const ScripDetail = React.lazy(() => import('./scrip/ScripDetail'));
const Radar = React.lazy(() => import('./scrip/Radar'));
const UserDashboard = React.lazy(() => import('./user/UserDashboard'));
const Portfolios = React.lazy(() => import('./user/Portfolios'));
const Portfolio = React.lazy(() => import('./user/Portfolio'));
const TopNav = React.lazy(() => import('../qrf/view/TopNav'));
const Home = React.lazy(() => import('./Home'));
const Article = React.lazy(() => import('./Article'));

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.chart = React.createRef();
    }

    static getDerivedStateFromProps() {
        return {};
    }

    render() {
        return (
            <Suspense fallback={<Loader /> || "Loading..."}>
                <div style={{cursor: "crosshair"}} id="TradingViewChart" />
                <Screen login={this.login}>
                    <Helmet>
                        <title>{this.state.title}</title>
                    </Helmet>

                    <Router>
                        <Routes>
                            <Route path="/my">
                                <Route path="trades" element={<Trades />} />
                                <Route path="dashboard" element={<UserDashboard />} />
                                <Route path="profile" element={<Profile />} />
                                <Route path="portfolios" element={<Portfolios />} />
                                <Route path="portfolio/:id" element={<Portfolio />} />
                                <Route path=":alias" element={<Home />} />
                            </Route>
                            {/*                     
                        <Route exact path="/top/:alias" element={<Scrips login={msg => this.login(msg)} />} />
                        <Route exact path="/my/">
                            <Route exact path="radar" element={[<Header />, <Radar login={msg => this.login(msg)} />]} />
                            <Route exact path="packs" element={<Packs login={msg => this.login(msg)} />}>
                                <Route exact path=":alias" element={<Packs login={msg => this.login(msg)} />} />
                            </Route>
                        </Route>
                        <Route exact path="/admin" element={<Admin login={msg => this.login(msg)} />} /> */}
                            <Route path='/company/outlook/:symbol' element={<Article />} />
                            <Route exact path="/view/scrip/:symbol" element={<Layout elements={[<Header />, <TopNav />, <ScripDetail />]} />} />
                            <Route exact path="/view/orders/:id/:portfolio/:symbol" element={<Orders />} />
                            <Route path='/page/:alias' element={<Article />} />
                            <Route path='/market/:alias' element={<Home />} />
                            <Route path='/index/:alias' element={<Home public />} />
                            <Route path='/:alias/:tag' element={<Home />} />
                            <Route path='/chart' element={<TradingViewChart ref={this.chart} container="TradingViewChart" />} />
                            <Route path='/charts' element={<Charts />} />
                            <Route path='*' element={<Home />} />
                        </Routes>
                    </Router>

                </Screen>
            </Suspense>
        )
    }

    login(msg = "") {
        return <>
            <Login afterLogin={() => this.setState({ authenticated: true })} heading={msg} />
            {/* <VideoBox /> */}
        </>
    }
}

export default Main;