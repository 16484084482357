import React from 'react';
import Button from '@salesforce/design-system-react/components/button';
import Layout from '../../qrf/grid/Layout';
import Header from '../common/Header';
import storage from '../../qrf/service/storage';
import DataTable from '../../qrf/view/DataTable/DataTableView';
import { INDICES } from '../../constants';
import { addToRadar, getScrip, onAction } from '../scrip/ScripCommon';
import TopNav from '../../qrf/view/TopNav';
import PortfolioObj from '../../objects/Portfolio';
import Modal from '../../qrf/view/Modal';
import Form from '../../qrf/view/Form';
import Trade from '../../objects/Trade';

class Trades extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: {},
            lists: [],
            item: {strategy: ""}
        }
        this.object = new Trade();
        this.addToRadar = addToRadar.bind(this);
        this.getScrip = getScrip.bind(this);
    }

    onAction(action, item) {
        this.setState({ newModal: !this.state.newModal, item });
    }

    newItem(modal = false) {
        if (modal) {
            this.setState({ newModal: !this.state.newModal });
            return;
        }
    }

    pageActions() {
        let actions = [];
        actions.push(<Button onClick={e => this.newItem(true)} label="New" />);
        return actions;
    }

    getPageControls() {
        let options = Object.keys(INDICES).map(key => { return { label: INDICES[key], value: key } })
        return [{ type: "list", options, label: "Indices" }];
    }

    onSelectIndices(selection) {
        let index = selection[0].id;
        let filter = this.state.filter;
        let nFilter = { indices: index }
        this.setState({ filter: { ...filter, ...nFilter } });
    }

    search(selection) {
        if (selection && selection.length) {
            this.setState({ search: { _id: { $in: selection.map(sel => sel.id) } }, items: [] });
        } else {
            this.setState({ search: null, items: [] });
        }
    }

    save(item) {
        this.object.save(item).then(res => {
            this.setState({ newModal: false, refresh: !this.state.refresh, item: {} });
        })
    }

    validate(ele, item, cb) {
        if (ele.name === "buyPrice" && item.buyPrice > 0) {
            let si = item.startingInvestment;
            let qty = Math.round(si / item.buyPrice);
            item.qty = qty;
            item.startingInvestment = (item.buyPrice * qty).toFixed(2);
            let aqty = Math.round((item.maxInvestment - item.startingInvestment) / item.buyPrice);
            item.maxInvestment = ((qty + aqty) * item.buyPrice).toFixed(2);
            item.aqty = aqty;
            cb(ele, item, cb);
        }
    }

    handleChange(ele, form) {
        if (ele.name === "symbol") {
            this.getScrip(form.symbol).then(res => {
                if (res.status) {
                    let item = {...this.state.item};
                    item.buyPrice = res.data.ltp;
                    this.setState({scrip: res.data, item});
                }
            });
        }
    }

    render() {
        return <><Layout protected elements={[
            <Header />,
            <TopNav />,
            <DataTable
                heading="Trades"
                pageActions={() => this.pageActions()}
                object={this.object}
                filter={this.state.filter}
                // pageCombo={{ object: INDICES, onSelect: (data) => this.onSelectIndices(data), loading: this.state.loading, multiple: true }}
                onAction={(action, item) => this.onAction(action, item)}
                search={this.state.search}
                {...this.props}
                scope="listview"
                pagination={false}
                noFilter
                refresh={this.state.refresh}
            />
        ]}
        />
            {this.state.newModal && <Modal footer={[]} open={this.state.newModal} onClose={e => this.setState({newModal: false, item: {}})} >
                {this.state.scrip && "LTP: " + this.state.scrip.ltp}
                <Form scope="formview" item={this.state.item} onFocusChange={(ele, item, cb) => this.validate(ele, item, cb)} onSubmit={item => this.save(item)} object={this.object} onChange={(ele, form) => this.handleChange(ele, form)} />
            </Modal>}
        </>
    }
}

export default Trades;