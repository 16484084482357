import React from 'react';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Icon from '@salesforce/design-system-react/components/icon';
import comboboxFilterAndLimit from '@salesforce/design-system-react/components/combobox/filter';

class MultiSelectCombo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            isLoadingMenuItems: false,
            selection: props.selection || [],
            options: props.options || []
        };
    }

    componentDidMount() {
        let comboObject = this.props.object || null;
        if (comboObject) {
            let options = [];
            Object.keys(comboObject).map(key => {
                options.push({
                    id: key,
                    label: comboObject[key],
                    type: 'account',
                })
            });
            this.setState({options})
        }
    }

    onChange(value) {
        this.setState({ inputValue: value, isLoadingMenuItems: true });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        return (
            <Combobox
                id="combobox-unique-id"
                events={{
                    onChange: (event, { value }) => {
                        this.onChange(value);
                    },
                    onRequestRemoveSelectedOption: (event, data) => {
                        this.setState({
                            inputValue: '',
                            selection: data.selection,
                        });
                    },
                    onSubmit: (event, { value }) => {
                        if (this.props.action) {
                            this.props.action('onChange')(event, value);
                        } else if (console) {
                            console.log('onChange', event, value);
                        }
                        this.setState({
                            inputValue: '',
                            selection: [
                                ...this.state.selection,
                                {
                                    label: value,
                                    icon: (
                                        <Icon
                                            assistiveText="Account"
                                            category="standard"
                                            name="account"
                                        />
                                    ),
                                },
                            ],
                        });
                    },
                    onSelect: (event, data) => {
                        this.setState({
                            inputValue: '',
                            selection: data.selection,
                        }, () => {
                            data.selection && typeof this.props.onSelect === "function" && this.props.onSelect(data.selection)
                        });

                        
                    },
                }}
                labels={{
                    label: this.props.label || '',
                    placeholder: this.props.placeholder || 'Please select',
                }}
                options={
                    this.state.isLoadingMenuItems
                        ? comboboxFilterAndLimit({
                            inputValue: this.state.inputValue,
                            options: this.state.options,
                            selection: this.state.selection,
                        })
                        : comboboxFilterAndLimit({
                            inputValue: this.state.inputValue,
                            options: this.state.options,
                            selection: this.state.selection,
                        })
                }
                selection={this.state.selection}
                value={this.state.inputValue}
                variant={this.props.variant || "base"}
                hasMenuSpinner={this.state.isLoadingMenuItems}
                disabled={this.props.disabled || this.state.selection.length === this.state.options.length}
                multiple={this.props.multiple || false}
                style={this.props.style}
                classNameContainer={this.props.className}
            />
        )
    }
}

export default MultiSelectCombo;