import React from 'react';
import Datepicker from '@salesforce/design-system-react/components/date-picker';
import moment from "moment/moment";

class Calendar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    handleChange = (event, data) => {
        this.setState({ value: data.date });
        this.props.onChange(data.date);
    };

    render() {
        return (
            <Datepicker
                labels={{
                    label: this.props.label || 'Date',
                }}
                onChange={(event, data) => {
                    this.handleChange(event, data);

                    if (this.props.action) {
                        const dataAsArray = Object.keys(data).map((key) => data[key]);
                        this.props.action('onChange')(event, data, ...dataAsArray);
                    } else if (console) {
                        console.log('onChange', event, data);
                    }
                }}
                onCalendarFocus={(event, data) => {
                    if (this.props.action) {
                        const dataAsArray = Object.keys(data).map((key) => data[key]);
                        this.props.action('onCalendarFocus')(event, data, ...dataAsArray);
                    } else if (console) {
                        console.log('onCalendarFocus', event, data);
                    }
                }}
                formatter={(date) => {
                    return date ? moment(date).format('M/D/YYYY') : '';
                }}
                parser={(dateString) => {
                    return moment(dateString, 'MM-DD-YYYY').toDate();
                }}
                value={this.state.value}
            />
        )
    }
}

export default Calendar;