import React from 'react';
import Col from './Col';

class Row extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let classes = "slds-grid slds-wrap " + this.props.className;
        if (this.props.vertical) {
            classes += " slds-grid_vertical";
        }
        if (this.props.button) {
            classes = "slds-x-small-buttons_horizontal slds-p-around_x-large slds-align_absolute-center";
        }
        if (this.props.children instanceof Array) {
            let colClasses = "";
            if (this.props.center) {
                colClasses += " slds-align_absolute-left";
            }
            let cols = [];
            let row = [];
            this.props.children.map((child, idx) => {
                row.push(child);
                let factor = (idx + 1) % 2;
                if (factor === 0) {
                    cols.push(<Col className={colClasses} length={this.props.length} key={`grid-col-${idx}`}>{row}</Col>);
                    row = [];
                }
            })
            return (
                <div className={classes}>
                    {cols}
                </div>
            )
        } else {
            return (
                <div className={classes}>
                    {this.props.children}
                </div>
            )
        }
    }
}

export default Row;