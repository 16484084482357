import moment from "moment/moment";
export const DATE_TIME_FORMAT = "DD MMM, YYYY h:mm:ss A";
export const SEARCH_LIMIT = 5;

export function getHost() {
    return process.env.REACT_APP_API_HOST;
    // let url = "http://127.0.0.1:5001/loji-fin/asia-south1/api/v1";
    // if (!window.location.contains("localhost")) {
    //     url = "https://api-lfvlw4rdja-el.a.run.app/api/v1";
    // }
    // return url;
}

export function toDate(dt) {
    return moment(dt).format("D MMM, YYYY - h:mm A")
}

export function tsToDate(ts = "") {
    if (!ts) {
        return "";
    }
    // let tsparts = ts.split("T");
    // let dt = tsparts[0];
    // let tm = tsparts[1].split(":");
    let m1 = moment(ts).utcOffset("330");
    return m1.format("D MMM, YYYY h:mm A");
}