import React from 'react';
import RadioButtonGroup from '@salesforce/design-system-react/components/radio-button-group';
import Radio from '@salesforce/design-system-react/components/radio-button-group/radio';
import Button from '../element/Button';
import Text from '../element/Text';
import Checkbox from '../element/Checkbox';
import Box from '../grid/Box';
import Buttons from '../element/Buttons';
import MultiSelectCombo from '../element/MultiSelectCombo';
import { INDICES } from '../../constants';
import Pills from './Pills';
import { Link } from 'react-router-dom';
import Row from '../grid/Row';
import Col from '../grid/Col';
import storage from '../service/storage';

class EditForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            item: props && props.item || {},
            edit: {}
        }
        this.object = this.props.object;
    }

    componentDidMount() {
        console.log("did")
    }

    componentDidUpdate(pp, ps) {
        if (JSON.stringify(this.props.item.id) !== JSON.stringify(this.state.item.id)) {
            this.setState({ item: this.props.item });
        }
    }

    onChange(ele, value) {
        let item = { ...this.state.item }
        let values = [];
        if (ele.type === "multiselect" || ele.multiple) {
            if (ele.multiple) {
                value.map(v => values.push(v.id))
            } else {
                values = value[0].id
            }
        } else {
            values = value;
        }
        item[ele.name] = values;
        this.setState({ item })
    }

    getInput(ele, readOnly = false) {
        if (!storage.getUser().is("admin")) {
            readOnly = true;
        }
        let item = { ...this.state.item };
        let value = this.state.item[ele.name];
        if (typeof ele.getValue === "function") {
            value = ele.getValue(value);
        }
        switch (ele.type) {
            case "text":
            case "password":
                return <Text form readOnly={readOnly} password={ele.type === "password"} onChange={value => this.onChange(ele, value)} name={ele.name} value={value} />
            case "checkbox":
                return <Checkbox onChange={value => this.setState({ [ele.name]: value })} name={ele.name} label={ele.label} />
            case "radiogroup":
                return <div>
                    <RadioButtonGroup
                        labels={{ label: ele.label || "" }}
                        onChange={(event) => this.setState({ checked: event.target.value })}
                        disabled={ele.disabled}
                        required={ele.required}
                        name={ele.name}
                        errorId={ele.errorId}
                    >
                        {ele.options.map((opt) => (
                            <Radio
                                key={opt.namme}
                                id={opt.name}
                                labels={{ label: opt.label }}
                                value={opt.value}
                                checked={opt.selected || this.state[ele.name] === opt.value}
                                variant="button-group"
                            />
                        ))}
                    </RadioButtonGroup>
                </div>
            case "select":
                let values = {};
                ele.options.map(prop => values[prop.value] = prop.label);
                if (!ele.multiple) {
                    value = [value];
                }
                return <MultiSelectCombo selection={value && value.map(val => val = { id: val, label: values[val] }) || []} multiple={ele.multiple} variant="readonly" options={ele.options.map(option => option = { id: option.value, label: option.label, type: "account" })} onSelect={value => this.onChange(ele, value)} disabled={readOnly} />

            case "multiselect":
                return ele.subtype === "pills" && <Pills placeholder=
                    {ele.placeholder} pills={this.state.item[ele.name]} onChange={pills => this.setState({ item: { ...item, [ele.name]: pills } })} />
        }
    }

    submit(e) {
        e.preventDefault();
        this.props.onSubmit(this.state);
    }

    render() {
        let { item } = this.state;
        return (
            item && <Box bodyClass="qrf-box2" icon={false}>
                {Object.keys(this.object.groups).map(group => (
                    <div className={"slds-box slds-m-left_medium slds-m-bottom_medium slds-list_horizontal slds-wrap qrf-box-item"}>
                        <Box heading={this.object.groups[group]}></Box>
                        <dl
                            key={item._id || Math.random()}
                            className="slds-m-auto slds-m-bottom_medium slds-list_horizontal slds-wrap"
                        >
                            {this.object.getProperties(group).map(prop => {
                                return (
                                    <>
                                        <dt
                                            className="slds-item_label slds-text-color_weak"
                                            title="Name"
                                            style={{ height: "35px" }}
                                        >
                                            {prop.label}
                                        </dt>
                                        <dd className="slds-item_detail" title={item[prop.label] || ""}>
                                            {/* <span onClick={e => this.setEdit(e, prop)}>
                                        {!this.state.edit[prop.name] && typeof item[prop.name] !== "object" && item[prop.name] || (typeof prop.Cell === "function" && prop.Cell(item[prop.name]))}
                                        </span> */}
                                            {this.getInput(prop, this.state.edit[prop.name])}
                                        </dd>
                                    </>
                                )
                            }
                            )
                            }
                        </dl>
                    </div>
                ))}
                {<div style={{ clear: "both" }}>
                    <Button onClick={e => this.props.onSubmit(this.state.item)} label="Save" />
                {this.props.buttons}
                </div>}
            </Box>
        )
    }
}

export default EditForm;