import React from 'react';
import TopNav from '../../qrf/view/TopNav';
import EventUtil from '@salesforce/design-system-react/utilities/event';
import Combobox from '@salesforce/design-system-react/components/combobox';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import GlobalHeader from '@salesforce/design-system-react/components/global-header';
import GlobalHeaderFavorites from '@salesforce/design-system-react/components/global-header/favorites';
import GlobalHeaderHelp from '@salesforce/design-system-react/components/global-header/help';
import GlobalHeaderNotifications from '@salesforce/design-system-react/components/global-header/notifications';
import GlobalHeaderProfile from '@salesforce/design-system-react/components/global-header/profile';
import GlobalHeaderSearch from '@salesforce/design-system-react/components/global-header/search';
import GlobalHeaderSetup from '@salesforce/design-system-react/components/global-header/setup';
import GlobalHeaderTask from '@salesforce/design-system-react/components/global-header/task';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Popover from '@salesforce/design-system-react/components/popover';
import auth from '../../qrf/service/auth';
import storage from '../../qrf/service/storage';
import Search from '../scrip/Search';
import Scrip from '../../objects/Scrip';
import moment from 'moment';
import Toast from '../../qrf/element/Toast';

/* eslint-disable max-len */
/* eslint-disable react/prop-types */

const ipsum =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec bibendum fermentum eros, vel porta metus dignissim vitae. Fusce finibus sed magna vitae tempus. Suspendisse condimentum, arcu eu viverra vulputate, mauris odio dictum velit, in dictum lorem augue id augue. Proin nec leo convallis, aliquet mi ut, interdum nunc.';

// Notifications content is currently the contents of a generic `Popover` with markup copied from https://www.lightningdesignsystem.com/components/global-header/#Notifications. This allows content to have tab stops and focus trapping. If you need a more specific/explicit `GlobalHeaderNotification` content, please create an issue.
const HeaderNotificationsCustomContent = (props) => (
    <ul id="header-notifications-custom-popover-content">
        {props.items.length > 0 ? props.items.map((item) => (
            <li
                className={`slds-global-header__notification ${item.unread ? 'slds-global-header__notification_unread' : ''
                    }`}
                key={`notification-item-${item.id}`}
            >
                <div className="slds-media slds-has-flexi-truncate slds-p-around_x-small">
                    {item.avatar && <div className="slds-media__figure">
                        <span className="slds-avatar slds-avatar_small">
                            <img
                                alt={item.name}
                                src={`/assets/images/${item.avatar}.jpg`}
                                title={`${item.name} avatar"`}
                            />
                        </span>
                    </div>}
                    <div className="slds-media__body">
                        <div className="slds-grid slds-grid_align-spread">
                            <a
                                href="#"
                                className="slds-text-link_reset slds-has-flexi-truncate"
                                onClick={(event) => event.preventDefault()}
                            >
                                <h3
                                    className="slds-truncate"
                                    title={`${item.name}`}
                                >
                                    <strong>{`${item.name}`}</strong>
                                </h3>
                                <p className="slds-truncate" title={item.comment}>
                                    {item.comment}
                                </p>
                                <p className="slds-m-top_x-small slds-text-color_weak">
                                    {item.timePosted}{' '}
                                    {item.unread ? (
                                        <abbr
                                            className="slds-text-link slds-m-horizontal_xxx-small"
                                            title="unread"
                                        >
                                            ●
                                        </abbr>
                                    ) : null}
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </li>
        )) : "Nothing here"}
    </ul>
);
HeaderNotificationsCustomContent.displayName = 'HeaderNotificationsCustomContent';
class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            notifications: storage.fromSession("notifications") || [{id: 1, name: "Hello", comment: "Welcome back"}]
        }
    }

    componentDidMount() {
        let user = auth.currentUser();
        this.setState({ user });
    }

    componentDidUpdate(p, s) {
        if (this.props.ws) {
            this.props.ws.mq.headerNotifications = message => {
                let msg = JSON.parse(message);
                if (!msg.group || msg.group === "webpush") {
                    if (Notification.permission === "granted") {
                        new Notification(msg.content);
                        let notifications = storage.fromSession("notifications") || [];
                        notifications.unshift({id: moment().format("YYYYMMDD:HHmmss"), name: moment().format("D, MMM h:mm A"), comment: msg.content});
                        storage.toSession("notifications", notifications);
                        this.setState({refresh: !this.state.refresh});
                    }
                }
            }
        }
        if (s.refresh !== this.state.refresh) {
            this.setState({notifications: storage.fromSession("notifications")});
        }
    }

    // Profile content is currently the contents of a generic `Popover` with markup copied from https://www.lightningdesignsystem.com/components/global-header/. This allows content to have tab stops and focus trapping. If you need a more specific/explicit `GlobalHeaderProfile` content, please create an issue.
    profileContent() {
        return (
            <div id="header-profile-custom-popover-content">
                <div className="slds-m-around_medium">
                    <div className="slds-tile slds-tile_board slds-m-horizontal_small">
                        <div className="slds-tile__detail">
                            <p className="slds-truncate">
                                <a
                                    className="slds-m-right_medium"
                                    href="/my/dashboard"
                                >
                                    Dashboard
                                </a>
                                <a href="#" onClick={e => this.logout(e)}>
                                    Log Out
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    logout(e) {
        e.preventDefault();
        storage.clearSession()
        window.location.href = "/";
    }

    render() {
        return (
            <>
                <GlobalHeader
                    logoSrc="/logo.png"
                    onSkipToContent={() => {
                        console.log('>>> Skip to Content Clicked');
                    }}
                    onSkipToNav={() => {
                        console.log('>>> Skip to Nav Clicked');
                    }}

                >
                    <GlobalHeaderSearch
                        combobox={
                            <Search redirect object={new Scrip()} search={this.state.search} onSearch={search => this.setState({ search })} />
                        }
                    />
                    {/* <GlobalHeaderFavorites
                        actionSelected={this.state.favoritesActionSelected}
                        onToggleActionSelected={(event, data) => {
                            this.setState({ favoritesActionSelected: !data.actionSelected });
                        }}
                        popover={
                            <Popover
                                ariaLabelledby="favorites-heading"
                                body={
                                    <div>
                                        <h2
                                            className="slds-text-heading_small"
                                            id="favorites-heading"
                                        >
                                            Favorites
                                        </h2>
                                        {ipsum}
                                    </div>
                                }
                                id="header-favorites-popover-id"
                            />
                        }
                    />
                    <GlobalHeaderTask
                        dropdown={
                            <Dropdown
                                id="header-task-dropdown-id"
                                options={[
                                    { id: 'taskOptionOne', label: 'Task Option One' },
                                    { id: 'taskOptionTwo', label: 'Task Option Two' },
                                ]}
                            />
                        }
                    />
                    <GlobalHeaderHelp
                        popover={
                            <Popover
                                ariaLabelledby="help-heading"
                                body={
                                    <div>
                                        <h2 className="slds-text-heading_small" id="help-heading">
                                            Help and Training
                                        </h2>
                                        {ipsum}
                                    </div>
                                }
                                id="header-help-popover-id"
                            />
                        }
                    />
                    <GlobalHeaderSetup
                        dropdown={
                            <Dropdown
                                id="header-setup-dropdown-id"
                                options={[
                                    { id: 'setupOptionOne', label: 'Setup Option One' },
                                    { id: 'setupOptionTwo', label: 'Setup Option Two' },
                                ]}
                            />
                        }
                    />
                    <GlobalHeaderNotifications
                        notificationCount={5}
                        popover={
                            <Popover
                                ariaLabelledby="header-notifications-custom-popover-content"
                                body={
                                    <HeaderNotificationsCustomContent
                                        items={[
                                            {
                                                action: 'mentioned you',
                                                avatar: 'avatar2',
                                                comment:
                                                    '@jrogers Could I please have a review on my presentation deck',
                                                id: 1,
                                                name: 'Val Handerly',
                                                timePosted: '10 hours ago',
                                                unread: true,
                                            },
                                            {
                                                action: 'commented on your post',
                                                avatar: 'avatar3',
                                                comment: 'I totally agree with your sentiment',
                                                id: 2,
                                                name: 'Jon Rogers',
                                                timePosted: '13 hours ago',
                                                unread: true,
                                            },
                                            {
                                                action: 'mentioned you',
                                                avatar: 'avatar2',
                                                comment:
                                                    "@jrogers Here's the conversation I mentioned to you",
                                                id: 3,
                                                name: 'Rebecca Stone',
                                                timePosted: '1 day ago',
                                            },
                                        ]}
                                    />
                                }
                                id="header-notifications-popover-id"
                            />
                        }
                    /> */}
                    {this.props.authenticated &&
                        <GlobalHeaderNotifications
                            notificationCount={this.state.notifications.length}
                            popover={
                                <Popover
                                    ariaLabelledby="header-notifications-custom-popover-content"
                                    body={
                                        <HeaderNotificationsCustomContent
                                            items={this.state.notifications}
                                        />
                                    }
                                    id="header-notifications-popover-id"
                                />
                            }
                        />}

                    {this.props.authenticated && <GlobalHeaderProfile
                        popover={
                            <Popover
                                body={this.profileContent()}
                                id="header-profile-popover-id"
                            />
                        }
                        userName={this.state.user.name || "Hello Howdy!"}
                    />
                    }
                </GlobalHeader>
                <div style={{ marginTop: "40px" }}>
                    {/* <TopNav /> */}
                    {this.props.children}
                    <Toast />
                </div>
            </>
        )
    }
}

export default Header;