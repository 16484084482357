import Combobox from '@salesforce/design-system-react/components/combobox';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { SEARCH_LIMIT } from '../../config';
import http from '../../qrf/service/http';
import storage from '../../qrf/service/storage';

class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            value: props.value || "",
            selection: []
        }
    }

    search(value) {
        if (value && this.props.object && value.length >= 3) {
            let search = this.props.object.toSearch(value);
            if (search && JSON.stringify(search) !== "{}") {
                http.post(this.props.object.getApi, { search, limit: SEARCH_LIMIT, filter: this.props.filter }).then(res => {
                    if (res.status) {
                        let options = res.data.items.map(item => {
                            return { id: item.symbol, label: item.company }
                        })
                        this.setState({ options })
                    }
                })
            }
        } else {
            this.setState({ options: [] })
            if (!value) {
                //this.props.onSelect("");
            }
        }
        this.setState({ value });
    }

    render() {
        return (
            <>
                <Combobox
                    assistiveText={{ label: 'Search' }}
                    events={{
                        onSelect: (event, data) => {
                            if (data && data.selection) {
                                let id = data.selection[0].id;
                                if (this.props.redirect) {
                                    window.location.href = "/view/scrip/" + id;
                                }
                                if (this.props.onSelect) {
                                    this.props.onSelect(data.selection);
                                }
                                this.setState({
                                    value: '',
                                    selection: data.selection,
                                });
                                //storage.toSession(thisid, data.selection);
                            }
                        },
                        onChange: (event, { value }) => {
                            this.search(value)
                        },
                        onSubmit: (event, { value }) => {
                            if (!value) {
                                this.props.onSelect("");
                            }
                        },
                        onRequestRemoveSelectedOption: (e, data) => {
                            if (data) {
                                this.setState({ selection: data.selection });
                                if (this.props.onSelect) {
                                    this.props.onSelect(data.selection);
                                }
                                // storage.toSession(thisid, data.selection);
                            }
                        }
                    }}
                    id="header-search-custom-id"
                    labels={{ placeholder: this.props.placeholder || 'Search Lojifin', label: this.props.label }}
                    options={this.state.options}
                    value={this.state.value}
                    selection={this.state.selection}
                    multiple={this.props.multiple || false}
                />
            </>
        )
    }
}

export default Search;