
import { createChart } from 'lightweight-charts';
import React, {
	createContext,
	forwardRef,
	useCallback,
	useContext,
	useEffect,
	useImperativeHandle,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import http from '../qrf/service/http';
import { STOCK_API } from '../constants';
import moment from 'moment/moment';

const Context = createContext();

const initialData = [
	{ time: '2018-09-01', value: 10.89 },
	{ time: '2018-09-02', value: 20.65 },
	{ time: '2018-09-03', value: 30.56 },
	{ time: '2018-09-04', value: 40.56 },
	{ time: '2018-09-05', value: 15.56 }
];
//const candleStickData = [];


const currentDate = new Date(initialData[initialData.length - 1].time);
// main component
export const TradingViewChart = props => {
	const {
		colors: {
			backgroundColor = 'white',
			lineColor = '#2962FF',
			textColor = 'black'
		} = {},
		timeVisible
	} = props;

	const [chartLayoutOptions, setChartLayoutOptions] = useState({});
	// The following variables illustrate how a series could be updated.
	const candleSeries = useRef(null);
	const series2 = useRef(null);
	const [started, setStarted] = useState(false);
	const [duration, setDuration] = useState("3m");
	const [candles, setCandles] = useState([...props.candles]);
	//const candleStickData = props.candles;
	useEffect(() => {
		if (props.candles.length !== candles.length) {
			setCandles([...props.candles]);
		}
	}, [props.candles]);

	// The purpose of this effect is purely to show how a series could
	// be updated using the `reference` passed to the `Series` component.
	useEffect(() => {
		if (candleSeries.current === null) {
			return;
		}

		if (started) {
			const interval = setInterval(() => {
				currentDate.setDate(currentDate.getDate() + 1);
				const next = {
					time: currentDate.toISOString().slice(0, 10),
					value: 53 - 2 * Math.random(),
				};
				candleSeries.current.update(next);
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [started]);

	useEffect(() => {
		setChartLayoutOptions({
			background: {
				color: backgroundColor
			},
			textColor,
			timeVisible
		});
	}, [backgroundColor, textColor, timeVisible]);

	return (
		<>
			<Chart layout={chartLayoutOptions} moves={props.moves}>
				<Series
					ref={candleSeries}
					type={'candlestick'}
					data={[...candles]}
					color={lineColor}
					duration={props.duration}					
				/>
				<Series
					ref={series2}
					type={'line'}
					data={props.rsi}
					color={lineColor}
				/>
			</Chart>
		</>
	);
};

export function Chart(props) {
	const [container, setContainer] = useState(false);
	const handleRef = useCallback(ref => setContainer(ref), []);
	return (
		<div ref={handleRef}>
			{container && <ChartContainer {...props} container={container} />}
		</div>
	);
}

export const ChartContainer = forwardRef((props, ref) => {
	const { children, container, layout, ...rest } = props;

	const chartApiRef = useRef({
		api() {
			if (!this._api) {
				let options = {
					...rest,
					layout,
					width: container.clientWidth,
					height: 500,
					timeScale: {
						timeVisible: false,
						secondsVisible: false
					}
					
				}
				if (props.timeVisible) {
					options = {...options, timeScale: {
						visible: true,
						timeVisible: true
					  }}
				}
				this._api = createChart(container, options);
				var api = this._api;

				const toolTipWidth = 80;
				const toolTipHeight = 80;
				const toolTipMargin = 15;

				// Create and style the tooltip html element
				const toolTip = document.createElement('div');
				toolTip.style = `width: 100px; height: 100px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 2px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
				toolTip.style.background = 'white';
				toolTip.style.color = 'black';
				toolTip.style.borderRadius = "5px";
				container.appendChild(toolTip);
				let moves = props.moves;
				// update tooltip
				api.subscribeCrosshairMove(param => {
					if (
						param.point === undefined ||
						!param.time ||
						param.point.x < 0 ||
						param.point.x > container.clientWidth ||
						param.point.y < 0 ||
						param.point.y > container.clientHeight
					) {
						toolTip.style.display = 'none';
					} else {
						// time will be in the same format that we supplied to setData.
						// thus it will be YYYY-MM-DD
						let format = "DD MMM, YYYY";
						if (typeof param.time === "number") {
							param.time = (param.time + 19800 + 3600) * 1000;
							format = "hh:mm";
						}
						const dateStr = moment(param.time).format(format);
						toolTip.style.display = 'block';
						//console.log([...param.seriesData.values()][0])
						const data = [...param.seriesData.values()][0];
						if (!data) {
							toolTip.style.display = 'none';
							return;
						}
						const price = data.close;
						let move = moves[param.time];
						if (move > 0) {
							move = "+" + move;
						}
						toolTip.innerHTML = `<div id="tooltip">
						<ul>
							<li>O: ${data.open}</li>
							<li>H: ${data.high}</li>
							<li>L: ${data.low}</li>
							<li>C: ${data.close}</li>
							<li style="font-weight:bold">${move? move + "%" : ""}</li>
						</ul>
			</div>`;
/**
 * <div style="color: ${'black'}">
			${dateStr}
			</div>
 */
						// const coordinate = api.priceToCoordinate(price);
						// let shiftedCoordinate = param.point.x - 50;
						// if (coordinate === null) {
						// 	return;
						// }
						// shiftedCoordinate = Math.max(
						// 	0,
						// 	Math.min(container.clientWidth - toolTipWidth, shiftedCoordinate)
						// );
						// const coordinateY =
						// 	coordinate - toolTipHeight - toolTipMargin > 0
						// 		? coordinate - toolTipHeight - toolTipMargin
						// 		: Math.max(
						// 			0,
						// 			Math.min(
						// 				container.clientHeight - toolTipHeight - toolTipMargin,
						// 				coordinate + toolTipMargin
						// 			)
						// 		);
						toolTip.style.left = (param.point.x + 50) + 'px';
						toolTip.style.top = (param.point.y - 50) + 'px';
					}
				});

				api.timeScale().fitContent();
			}
			this._api.applyOptions(
				{timeScale: {
					visible: true,
					timeVisible: true
				  }})
			return this._api;
		},
		free() {
			if (this._api) {
				this._api.remove();
			}
		},
	});

	useLayoutEffect(() => {
		const currentRef = chartApiRef.current;
		const chart = currentRef.api();

		const handleResize = () => {
			chart.applyOptions({
				...rest,
				width: container.clientWidth,
				crosshair: {
					// Change mode from default 'magnet' to 'normal'.
					// Allows the crosshair to move freely without snapping to datapoints
					mode: 0,

					// Vertical crosshair line (showing Date in Label)
					vertLine: {
						color: '#9B7DFF',
						labelBackgroundColor: '#9B7DFF',
					},

					// Horizontal crosshair line (showing Price in Label)
					horzLine: {
						color: '#9B7DFF',
						labelBackgroundColor: '#9B7DFF',
					},
				}
			});
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
			chart.remove();
		};
	}, []);

	useLayoutEffect(() => {
		const currentRef = chartApiRef.current;
		currentRef.api();
	}, []);

	useLayoutEffect(() => {
		const currentRef = chartApiRef.current;
		currentRef.api().applyOptions({
			...rest, crosshair: {
				// Change mode from default 'magnet' to 'normal'.
				// Allows the crosshair to move freely without snapping to datapoints
				mode: 0,

				// Vertical crosshair line (showing Date in Label)
				vertLine: {
					width: 1,
					color: '#9B7DFF',
					style: 1,
					labelBackgroundColor: '#9B7DFF',
				},

				// Horizontal crosshair line (showing Price in Label)
				horzLine: {
					color: '#9B7DFF',
					labelBackgroundColor: '#9B7DFF',
				},
			}
		});
	}, []);

	useImperativeHandle(ref, () => chartApiRef.current.api(), []);

	useEffect(() => {
		const currentRef = chartApiRef.current;
		currentRef.api().applyOptions({ layout });
	}, [layout]);

	return (
		<Context.Provider value={chartApiRef.current}>
			{props.children}
		</Context.Provider>
	);
});
ChartContainer.displayName = 'ChartContainer';

export const Series = forwardRef((props, ref) => {
	const [duration, setDuration] = useState("3m");
	const parent = useContext(Context);
	const context = useRef({
		api() {
			if (!this._api) {
				const { children, data, type, ...rest } = props;
				if (!this.chart) {
					this.chart = parent.api();
				}
				// this._api = type === 'line'
				// 	? parent.api().addLineSeries(rest)
				// 	: parent.api().addAreaSeries(rest);
				if (type === "candlestick") {
					this._api = this.chart.addCandlestickSeries(rest);
                    this._api.priceScale().applyOptions({
                        scaleMargins: {
                            top: 0.1, // highest point of the series will be 10% away from the top
                            bottom: 0.4, // lowest point will be 40% away from the bottom
                        },
                    });
				} else {
					let lineOptions = {
						title: "RSI",
						priceScaleId : "",
                        lineWidth:2
					}
					this._api = this.chart.addLineSeries(lineOptions);
					this._api.priceScale('').applyOptions({
						scaleMargins: {
							top: 0.7,
							bottom: 0.1,
						},
					});
				}
				this._api.setData(data);
			}
			return this._api;
		},
		free() {
			if (this._api) {
				parent.free();
			}
		},
	});

	useLayoutEffect(() => {
		const currentRef = context.current;
		currentRef.api();

		//return () => currentRef.free();
	}, []);

	useLayoutEffect(() => {
		const api = context.current.api();
		const { children, data, ...rest } = props;
		api.applyOptions(rest);
		api.setData(data);
		context.current.chart.timeScale().fitContent();
	}, [props.data]);

	useImperativeHandle(ref, () => context.current.api(), []);
	// useLayoutEffect(() => {

	// 	const currentRef = context.current;
	// 	const { children, data, ...rest } = props;
	// 	currentRef.api().applyOptions(rest);
	// 	currentRef.api().setData([]);
	// 	currentRef.api().setData(data);
	// 	//currentRef.api().timeScale().fitContent();

	// 	//currentRef.fitContent();
	// 	//return () => currentRef.api();
	// }, [props.data])
	return (
		<Context.Provider style={{cursor: "crosshair"}} value={context.current}>
			{props.children}
		</Context.Provider>
	);
});
Series.displayName = 'Series';